import { rarityInstance } from "../../config/axios";

const fetchUserSettings = async () => {
  try {

   
    const response = await rarityInstance().get(`users/setting`);
    // console.log("res", response)
    if (response?.data?.status === 200) {
      const diarySettingsObj = response?.data?.userSettings?.diarySettings;
      const companyLogoURL = response?.data?.userSettings?.companylogo;
      return { diarySettingsObj, companyLogoURL };
    }
  } catch (error) {
    console.log("Error while fetching user settings---", error);
  }
};

export default fetchUserSettings;
