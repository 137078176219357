import { useRef, useEffect } from "react";
import Form from "react-bootstrap/Form";

const DiamondWeight = ({
  payload,
  handleOnchange,
  inputError,
  setStepScroll,
  activeGia,
  setActiveGia,
}) => {
  const inputRef = useRef(null);

  // const screenWidth = window.innerWidth;

  useEffect(() => {
    if (document.readyState === "complete") {
      inputRef.current.focus();
    }
  }, []);

  return (
  
    <div
      className="diamond-weight-wrapper diamond-weight-container "
      id="diamondWeight"
    >
      <h5>Type in the Weight</h5>
      <Form.Group
        className={`diamond-weight ${inputError ? "error" : ""}`}
        controlId="formBasicEmail"
      >
        <Form.Label>Carats</Form.Label>

        <Form.Control
          // type={screenWidth > 991 ? "number" : "text"}
          type="number"
          pattern="[0-9]*/^\d*\.?\d*$/"
          inputMode="decimal"
          placeholder="Enter weight"
          name="weight"
          min="0.18"
          max="99"
          value={payload?.weight}
          onWheel={(e) => e.target.blur()}  
          onFocus={() => setStepScroll(false)}
          onChange={(e) => handleOnchange(e.target.value, e.target.name, 1)}
          onBlur={() => setStepScroll(true)}
          ref={inputRef}
          autoFocus={true}
        />
        {inputError && (
          <span className="error-text">
            Please enter value between 0.18 to 99
          </span>
        )}

{activeGia &&
        <div className={`manual_generate ${activeGia?" ":"By_Gia"}`}>
          <div className="generat-ortext">
            <p>OR</p>
          </div>
          <p
            onClick={() => {
              setActiveGia(!activeGia);
            }}
          >
            Generate Manually
             {/* {activeGia ? "Manually" : "By Gia"} */}
          </p>
        </div>}
      </Form.Group>
    </div>
  
  );
};

export default DiamondWeight;
