export const DiaryOriginImage = [
    {
      origin: "Angola - Catoca",
      Img: "Catoca.png",
    },
    
    {
      origin: "Angola - Lulo (Lucapa)",
      Img: "Lulo.png",
    },
    {
      origin: "Australia - Argyle",
      Img: "Argyle mine.png",
    },
    {
      origin: "Botswana - Jwaneng (De Beers)",
      Img: "Jwaneng.png",
    },
    {
      origin: "Botswana - Orapa",
      Img: "Botswana - Orapa.png",
    },
    {
      origin: "Brazil - Diamantina",
      Img: "Diamantina Brazil mine.png",
    },
    {
      origin: "Canada - Diavik (Rio Tinto)",
      Img: "Diavik.png",
    },
    {
      origin: "Canada - Ekati",
      Img: "Misery mine.png",
    },
    {
      origin: "Congo - Kinshasa",
      Img: "Mbuji-Mayi mine.png",
    },
    {
      origin: "Lesotho - Kao",
      Img: "Kao - Lesoto.png",
    },
    {
      origin: "Russia - Almazy Anabara",
      Img: "Sever Almaz.png",
    },
      // {
    //   origin: "Russia - Severalmaz",
    //   Img: "",
    // },
    {
      origin: "Sierra Leone - Zimmi",
      Img: "Zimmi.png",
    },
    {
      origin: "South Africa - Rooipoort",
      Img: "South Africa - Rooipoort.png",
    },
    {
      origin: "South Africa - Alexkor",
      Img: "Alexkor mine.png",
    },
    {
      origin: "South Africa - De Punt Mine (Trans Hex)",
      Img: "De Punt.png",
    },
    {
      origin: "South Africa - Finsch",
      Img: "Finch.png",
    },
    {
      origin: "South Africa - Premier (Cullinan, Petra)",
      Img: "Culinan.png",
    },
    {
      origin: "South Africa - The Somiluana Mine (Trans Hex)",
      Img: "Somiluana - trans Hex.png",
    },
    {
      origin: "South Africa - Venetia",
      Img: "Venetia mine South Africa.png",
    },
    {
      origin: "South Africa - Williamson (Tanzania)",
      Img: "Williamson.png",
    },
    {
      origin: "Zimbabwe - Murowa",
      Img: "Murowa.png",
    },
  ];
