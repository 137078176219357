import axios from 'axios';
import { serverPath } from './key';
export const withoutAuth = () => {
  return axios.create({
    baseURL: `${serverPath}`,
    headers:{
      userLocation: localStorage.getItem('curr_location')
    }
  });
};

export const rarityInstance = () => {

  // start=>
  const product_id = localStorage.getItem("product_id") || '';
  const product_name = localStorage.getItem("item_name") || '';
  const member_type = localStorage.getItem('is_role') || '';
  const {is_a_child_user, parent_user_email} = JSON.parse(localStorage.getItem("is_child_access")) || {};
  const childUser = is_a_child_user === true ? parent_user_email : 'FALSE' ;
  const userExtraDetails = {
    product_id,
    product_name,
    member_type,
    childUser
  };
  // <=End
  // console.log('navigator.geolocation ===',navigator.geolocation )
  return axios.create({
    baseURL: serverPath,
    headers: {
      Authorization: localStorage.getItem('rarity#@user')
        ? `${'Bearer '}${localStorage.getItem('rarity#@user')}`
        : '', 
      userExtraDetails : JSON.stringify(userExtraDetails),
      userLocation: localStorage.getItem('curr_location')
    },
    timeout: 1000 * 10000,
  });
};
