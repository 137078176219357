import React, { useContext, useEffect, useRef, useState } from "react";
import FcrfContext from "../../Context";
import Reel from "react-reel";
import { useInView } from "react-intersection-observer";
let count = 1;

const DiaryRarity = ({
  rarityInfo,
  data,
  color,
  goToRairtyReport,
  sidebarCard,
  handleSideBarCard,
  isHide,
  stringDate,
  
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
  // console.log("data ==>", data)
  const rarityText1 = rarityInfo?.C64?.split("#") || [];
  const rarityText2 = rarityInfo?.C66?.split("#") || [];
  const [animateText, setAnimateText] = useState(Number(rarityInfo?.E64) - 5);
  // const [viewOnce, setViewOnce] = useState(false);
  const [gradeAnimate, setGradeAnimate] = useState(false);
  const gemologicalRef = useRef();
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });
  //diaryPayload, setdiaryPayloads
  useEffect(() => {
    const rarityBox = {
      heading: rarityInfo?.B62,
      rarityText1: rarityText1?.length > 0 ? rarityText1 : null,
      rarityText2: rarityText2?.length > 0 ? rarityText2 : null,
      rarityGrade: rarityInfo?.C68 ? rarityInfo?.C68 : "",
      rarityLastUpdate: `${rarityInfo?.D68} ${rarityInfo?.E68}`,
    };
    setPdfReportData((prev) => ({ ...prev, rarityBox }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rarityInfo]);

  // const section = document.getElementById("rarityBox");

  // useEffect(() => {
  //   window.addEventListener("scroll", navHighlighter);
  //   return () => {
  //     window.removeEventListener("scroll", navHighlighter);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [section]);

  // function navHighlighter() {
  //   let scrollY = window.scrollY;
  //   const sectionHeight = section?.offsetHeight;
  //   const sectionTop = section?.offsetTop - 500;
  //   if (scrollY >= sectionTop && scrollY <= sectionTop + sectionHeight) {
  //     setTimeout(() => {
  //       setViewOnce(true);
  //     }, 800);
  //   }
  // }

  const boldText = rarityText1[1]?.split(" ");
  // const NumBoldText = Number(rarityInfo?.E64);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     if (count <= 5 && viewOnce) {
  //       setAnimateText((old) => old + 1);
  //       count++;
  //     } else if (count === 5) {
  //       clearInterval(interval);
  //       setGradeAnimate(true);
  //     }
  //   }, 1000);
  //   return () => {
  //     count = 1;
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [viewOnce]);

  useEffect(() => {
    setAnimateText(Number(rarityInfo?.E64) - 5)
    if (inView && rarityInfo?.E64) {
      
     const interval = setInterval(() => {
        if (count <= 5  &&animateText<=rarityInfo?.E64  ) {
           setAnimateText((old) => old + 1);
          count++;
          
         } else if (count === 6) {
         
          setGradeAnimate(true);
          
          clearInterval(interval);
          }
   }, 1000);
   return () => {
        clearInterval(interval);
      };
     
    }
    // eslint-disable-next-line
  }, [inView,rarityInfo?.E64]);
  

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const theme = {
    reel: {
      height: "16px",
      display: "flex",
      alignItems: "flex-end",
      overflowY: "hidden",
      fontSize: "16px",
      fontWeight: "600",
      color: "#181f37",
      lineHeight: "17px",
    },
    group: {
      transitionDelay: "0ms",
      transitionTimingFunction: "ease-in-out",
      transform: "translate(0, 0)",
      height: "16px",
    },
    number: {
      height: "16px",
    },
  };

  const getReelTheme = () => ({
    ...theme,
    reel: {
      ...theme.reel,
    },
  });
  return (
    <section
      className={`diary-card ${sidebarCard?.rarityBox ? "show" : "hide"}`}
      id="rarityBox"
    >
      {!isHide && (
        <div className="mobile-hide-card-btn">
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-rarityBox`}
              name={`rarityBox`}
              onChange={() =>
                handleSideBarCard("rarityBox", sidebarCard?.rarityBox)
              }
              checked={sidebarCard?.rarityBox}
            />
            <div className="checkmark"></div>
          </label>
        </div>
      )}
      <div>
        {/* <h4>{rarityInfo?.B62}</h4> */}
        <div className="diary-card-content polished-card-content">
          <div className="rarity-general">
            <div className="rarity-info-des">
              <h4 className="rarity-title">{rarityInfo?.B62}</h4>
              <div className="diary-rarity text wrapper">
                <div  ref={(node) => {
        gemologicalRef.current = node;
        inViewRef(node);
      }} className="diary-rarity-text-reel-wrapper">
                  {rarityText1[0]}
                  <b>
                    {boldText[0]} {boldText[1]} {boldText[2]}
                  </b>
                  <span>
                    <Reel
                      theme={getReelTheme()}
                      text={`${numberWithCommas(animateText)}`}
                    />
                  </span>

                  {rarityText1[2]}
                </div>
                <p>
                  {rarityText2[0]}
                  <b>{rarityText2[1]}</b>
                  {rarityText2[2]}
                </p>
              </div>
            </div>
           
          </div>

          {/* <div className="rarity-info-mob">
            <h4 className="rarity-title">{rarityInfo?.B62}</h4>
            <p>
              {rarityText1[0]}
              <b>{rarityText1[1]}</b>
              {rarityText1[2]}
            </p>
          </div> */}
          <div className="rarity-grade-wrapper">
            <div className="rarity-grade">
              <div className="rarity-grade-text">
                <p>Rarity Grade</p>
               
                <span
                  className={`grade-info-text ${gradeAnimate ? "animate" : ""}`}
                >
                  {rarityInfo?.C68}
                </span>
              </div>
            </div>
            <div className="rarity-grade-point-wrapper">
            <span className="text-url">
              <span
                className="rarity-link rarity-text-link"
                onClick={() => goToRairtyReport()}
                role="presentation"
              >
                Explore the full rarity report
              </span>
              {/* <p className="rarity-link-text">
                {rarityInfo?.D68} {stringDate}
              </p> */}
            </span>
              {/* <div className="rarity-grade-point-content">
                <p>
                  <span>Notable </span>(900-10,000 yearly)
                </p>
                <p>
                  <span>Outstanding </span>(400-899 yearly)
                </p>
                <p>
                  <span>Special </span>(180-399 yearly)
                </p>
                <p>
                  <span>Very Special </span>(30-179 yearly)
                </p>
                <p>
                  <span>Extraordinary </span>(5-29 yearly)
                </p>
              </div>
              <div className="rarity-grade-divider" />
              <div className="rarity-grade-point-content">
                <p>
                  <span>Rare </span>1 - 4 every year
                </p>
                <p>
                  <span>Very Rare </span>1x every 1 - 10y
                </p>
                <p>
                  <span>Extremely Rare </span>1x every 9 - 18y
                </p>
                <p>
                  <span>Exceptional </span>1x every 19 - 24y
                </p>
                <p>
                  <span>Remarkable </span>1x every 25y
                </p>
              </div> */}
            </div>
          </div>
          {/* <span className="text-url mobile">
            <span
              className="rarity-link rarity-text-link"
              onClick={() => goToRairtyReport()}
              role="presentation"
            >
              Full version PDF
            </span>
            <p className="rarity-link-text">{rarityInfo?.D68}{stringDate}</p>
          </span> */}
        </div>
      </div>
    </section>
  );
};

export default DiaryRarity;
