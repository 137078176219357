import { useContext } from "react";
import Form from "react-bootstrap/Form";
import FcrfContext from "../../Context";
// import { DiamondSaturations } from "../../utils/diamondsDetail";
import { DiamondAssets } from "../../utils/diamondsDetail";

const DiamondSaturation = ({ payload, handleOnchange }) => {
  const getContext = useContext(FcrfContext);
  const { rarityColor } = getContext;

  const result = DiamondAssets.filter((elem) => payload.color === elem.value);
  const DiamondSaturation = result[0]?.saturation;

  // console.log("result>>", result, payload.color);
  // console.log("DiamondSaturation>>", DiamondSaturation);

  return (
    <div
      className="diamond-color-wrapper diamond-weight-wrapper diamond-saturation-wrapper"
      id="diamondSaturation"
    >
      <h5>Select the saturation of the diamond:</h5>
      <div className="diamond-shape-grid">
        {DiamondSaturation?.map((el, i) => (
          <Form.Group className="diamond-btn-wrapper" key={i}>
            <input
              type="radio"
              id={`diamond${el}`}
              name="saturation"
              value={payload?.saturation}
              onChange={(e) => handleOnchange(el, e.target.name, 4)}
              checked={payload?.saturation === el}
            />
            <div className="diamond-color-box">
              <img
                className={`diamond-saturation ${el}`}
                src={`images/diamond-${rarityColor.color}-${rarityColor?.shape}.svg`}
                alt={el}
              />
              <span>{el}</span>
            </div>
          </Form.Group>
        ))}
      </div>
    </div>
  );
};

export default DiamondSaturation;
