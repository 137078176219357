import { useState, useEffect, useContext } from "react";
import { Spinner, Button } from "react-bootstrap";
import DiamondWeight from "./diamondWeight";
import DiamondColor from "./diamondColor";
import DiamondShape from "./diamondShape";
import DiamondSaturation from "./diamondSaturation";
import DiamondClarity from "./diamondClarity";
import { withoutAuth } from "../../config/axios";
import { useNavigate, useLocation } from "react-router-dom";
import FcrfContext from "../../Context";
import Loader from "../loader/loader";
import "../rarity/rarity.css";
import "./rarityArgyle.css";
import { rarityHandlePayload } from "../../utils/rarityStepPayload";
import { Helmet } from "react-helmet";
import PageLoader from "../loader/pageLoader";
import getLocalStorageData from "../../utils/commonfunctions/getLocalStorageData";
import fetchUserSettings from "../../utils/commonfunctions/fetchUserSettings";
import getlastUpdate from "../../utils/commonfunctions/getlastUpdate";

const RarityArgyle = ({
  showArgyleContent,
  setShowArgyleContent,
  setLoader,
  loader
}) => {
  const getContext = useContext(FcrfContext);
  const {
    steps,
    setIsWp,
    inputError,
    setInputError,
    setStepScroll,
    setBackScroll,
    setMainDiary_collab_credit,
    mainrarity_collab_credit,
    setMainRarity_collab_credit,
    setIsActiveVerify,
    is_rarity_cradits,
    setis_rarity_cradits,
    setis_credits_included,
    setis_diary_balance,
    setCompanyLogo,
    setSidebarCard,
    setSidebarCardMainCopy,
    setDateSetting,
  } = getContext;
  const setContext = useContext(FcrfContext);
  const navigate = useNavigate();
  const location = useLocation();
  const initialState = {
    type: "",
    weight: "",
    carat: "",
    color: "",
    saturation: "",
    shape: "",
    clarity: "",
    select: "",
  };
  const [payload, setPayload] = useState(initialState);
  const [, /* diamondClarity */ setDiamondClarity] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaderType, setLoaderType] = useState("loginalert");
  const [isActive, setIsActive] = useState(false);
  const handleOnchange = (value, name, step) => {
    if (name === "weight" && (value < 0.1 || value > 4)) {
      setInputError(true);
      const stepStr = `?Step=${step}`;
      window.history.pushState(step, null, stepStr);
      setContext.setSetps(step);
      const newPayload = rarityHandlePayload(value, name, step, payload, from);
      setPayload(newPayload);
      return;
    }
    setInputError(false);
    const stepStr = `?Step=${step}`;
    window.history.pushState(step, null, stepStr);
    if (value) {
      const nextStep = step + 1;
      setContext.setSetps(nextStep);
    } else {
      setContext.setSetps(step);
    }
    const newPayload = rarityHandlePayload(value, name, step, payload, from);
    setPayload(newPayload);
  };

  const props = {
    payload,
    handleOnchange,
    inputError,
    setStepScroll,
  };

  const { from, data } = location?.state || {};
  useEffect(() => {
    if (from === "argyle-report") {
      setPayload(data);
    }
  }, [from, data]);

  const handleSubmit = async () => {
    setIsActive(true);
    const { rarityuser } = getLocalStorageData();
    console.log("rarityuser", rarityuser)

    if (rarityuser=== "undefined") {
      setLoading(true);
      return;
    }
    if (!rarityuser) {
      setTimeout(() => {
        handleTokenCheking();
      }, 3000);
    } else {
      handleTokenCheking();
    }
  };

  const handleTokenCheking = async () => {
    try {
      console.log("token")

      const { is_role, item_name,service,is_subscription,subscription_id,product_id,is_child_access,rarityuser,
      } = getLocalStorageData();
      const servicesArr = service?service:[];
      const child_access = JSON.parse(is_child_access);
      const { is_a_child_user, parent_subscription_status, rarity_cradits } =
        child_access || {};
      console.log("is_child_access 2===>", child_access);

      const is_active_free_account = localStorage.getItem(
        "is_active_free_account"
      );

      if (!rarityuser) {
        setLoading(true);
        return;
      }

      if (
        is_role === "administrator" ||
        is_role === "contributor" ||
        is_role === "brands"
      ) {
        navigate("/argyle-report", {
          state: { filter: payload, action: "Create", from: "argyle" },
        });
      } else if (is_role === "industry") {
        if (mainrarity_collab_credit === "-1" || mainrarity_collab_credit > 0) {
          navigate("/argyle-report", {
            state: { filter: payload, action: "Create", from: "argyle" },
          });
        } else {
          console.log("not okkk ==>");
          setLoaderType("is_branding");
          console.log("is_member");
          setLoading(true);
          return;
        }
      } else if (is_role === "data_supplier") {
        if (is_subscription === "active" && subscription_id) {
          navigate("/argyle-report", {
            state: { filter: payload, action: "Create", from: "argyle" },
          });
        } else {
          setLoaderType("is_data_supplier");
          console.log("is_member");
          setLoading(true);
          return;
        }
      } else {
        const productID = [
          process.env.REACT_APP_FREE,
          process.env.REACT_APP_MONTH,
          process.env.REACT_APP_ASS,
          process.env.REACT_APP_PRO,
          process.env.REACT_APP_PRONEW,
          process.env.REACT_APP_PROCOP,
          process.env.REACT_APP_PROCON,
          process.env.REACT_APP_IND,
        ];

        if (is_a_child_user && parent_subscription_status === "active") {
          if (rarity_cradits === "-1" || rarity_cradits > 0) {
            navigate("/argyle-report", {
              state: { filter: payload, action: "Create", from: "argyle" },
            });
            return;
          } else {
            console.log("contact to parent ");
            setLoaderType("is_search_up_child");
            setLoading(true);
            return;
          }
        }

        if (is_a_child_user && parent_subscription_status !== "active") {
          console.log("not okkk ==>");
          setLoaderType("is_member");
          console.log("is_member");
          setLoading(true);
          return;
        }

        if (productID.includes(product_id)) {
          if (
            product_id === process.env.REACT_APP_IND &&
            !servicesArr.includes("rarity")
          ) {
            setLoaderType("is_upgrade");
            setLoading(true);
            return;
          }
          if (is_role === "individual" && !servicesArr.includes("rarity")) {
            setLoaderType("is_rarity");
            setLoading(true);
            return;
          }

          if (is_subscription !== "active") {
            setLoaderType("is_expired");
            setLoading(true);
            return;
          }

          if (
            item_name === "" ||
            product_id === "" ||
            item_name === null ||
            product_id === null
          ) {
            setLoaderType("is_rarity");
            setLoading(true);
            return;
          }

          if (
            product_id === process.env.REACT_APP_FREE &&
            is_active_free_account === "false"
          ) {
            setLoaderType("is_free_status");
            setLoading(true);
            return;
          }

          if (
            product_id === process.env.REACT_APP_FREE &&
            is_active_free_account === "true" &&
            parseInt(is_rarity_cradits) <= 0 &&
            is_rarity_cradits !== "-1"
          ) {
            setLoaderType("is_free");
            setLoading(true);
            return;
          }

          if (parseInt(is_rarity_cradits) <= 0 && is_rarity_cradits !== "-1") {
            setLoaderType("is_search_up");
            setLoading(true);
            return;
          }

          navigate("/argyle-report", {
            state: { filter: payload, action: "Create", from: "argyle" },
          });
        } else {
          setLoaderType("is_member");
          setLoading(true);
          return;
        }
      }
    } catch (e) {
      setDiamondClarity(false);
    }
  };

  useEffect(() => {
    if (loader) {
  //     const token =
//     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3NTYsInRpbWUiOjE3MTUzMzM2NTV9.j-sbzDOAZjCowM48mdqXNUQ8O74uUa1rOKMaUSWfv18";
//     verifyUser(token);
// return;
    const interval = setInterval(() => {
      window.parent.postMessage("iframeIsReadyToReceiveUserData", "*");
      }, 1000);

      const handleMessage = (event) => {
        setIsActiveVerify(true);
        console.log("event.origin", event.origin);
        console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
        if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
          const {
            is_free,
            token,
            status,
            role,
            billing_company,
            is_wp,
            activeserviceArr,
            item_name,
            product_id,
            type,
            active_free_account,
            is_active_free_account,
            is_rarity_cradits,
            is_credits_included,
            is_diary_balance,
            subscription_id,
            custom_branding,
            diary_collab_credit,
            rarity_collab_credit,
            is_child_access,
          } = event?.data;

          setIsWp(is_wp);
          console.log("token come form wp diary==>", token);
          console.log("event?.data==>", event?.data);

          if (token) {
            setMainDiary_collab_credit(diary_collab_credit);
            setMainRarity_collab_credit(rarity_collab_credit);
            setis_diary_balance(is_diary_balance);
            setis_credits_included(is_credits_included);
            setis_rarity_cradits(is_rarity_cradits);
            setIsActiveVerify(false);
            localStorage.setItem("is_free", is_free);
            localStorage.setItem("is_subscription", status);
            localStorage.setItem("is_role", role);
            localStorage.setItem("billing_company", billing_company);
            localStorage.setItem("service", JSON.stringify(activeserviceArr));
            localStorage.setItem("item_name", item_name);
            localStorage.setItem("product_id", product_id);
            localStorage.setItem("type", type);
            localStorage.setItem("active_free_account", active_free_account);
            localStorage.setItem(
              "is_active_free_account",
              is_active_free_account
            );
            localStorage.setItem("subscription_id", subscription_id);
            localStorage.setItem("custom_branding", custom_branding);
            localStorage.setItem("is_child_access", is_child_access);
            verifyUser(token);
          } else {
            localStorage.removeItem("rarity#@user");
          }
        } else {
          if (!localStorage.getItem("rarity#@user")) {
            if (window.location !== window.parent.location) {
              // The page is in an iframe
            } else {
              // The page is not in an iframe
              window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
            }
          }
        }

        if (event?.data) {
          clearInterval(interval);
        }
      };

      window.addEventListener("message", handleMessage, false);

      return () => {
        window.removeEventListener("message", handleMessage);
        clearInterval(interval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, loader]);

  const verifyUser = async (token) => {
    try {
      const response = await withoutAuth().post("users/verifyUser", {
        tokenid: token,
      });
      const {
        token: usertoken,
        statusofsub,
        role,
        activeservice,
        billing_company,
        item_name,
        product_id,
        type,
        diaryattempted,
        rarityattempted,
        argyleattempted,
        active_free_account,
        is_active_free_account,
        is_rarity_cradits,
        is_credits_included,
        is_diary_balance,
        subscription_id,
        diary_collab_credit,
        rarity_collab_credit,
        is_child_access,
      } = response?.data || {};
      console.log("come token from nest= >", usertoken);
      setMainDiary_collab_credit(diary_collab_credit);
      setMainRarity_collab_credit(rarity_collab_credit);
      setis_diary_balance(is_diary_balance);
      setis_credits_included(is_credits_included);
      setis_rarity_cradits(is_rarity_cradits);
      setIsActiveVerify(false);
      localStorage.setItem("rarity#@user", usertoken);
      localStorage.setItem("is_subscription", statusofsub);
      localStorage.setItem("is_role", role);
      localStorage.setItem("billing_company", billing_company);
      localStorage.setItem("service", JSON.stringify(activeservice));
      localStorage.setItem("item_name", item_name);
      localStorage.setItem("product_id", product_id);
      localStorage.setItem("type", type);
      localStorage.setItem("dairyattemptedcount", diaryattempted);
      localStorage.setItem("attemptedcount", rarityattempted);
      localStorage.setItem("argyleattempted", argyleattempted);
      localStorage.setItem("active_free_account", active_free_account);

      localStorage.setItem("is_active_free_account", is_active_free_account);
      localStorage.setItem("is_rarity_cradits", is_rarity_cradits);
      localStorage.setItem("is_credits_included", is_credits_included);
      localStorage.setItem("is_diary_balance", is_diary_balance);
      localStorage.setItem("subscription_id", subscription_id);
      localStorage.setItem("is_child_access", is_child_access);
      setLoader(false);
      const { diarySettingsObj, companyLogoURL } = await fetchUserSettings();
      const { data } = await getlastUpdate();
      if (data) {
        setDateSetting(data);
      }
      if (diarySettingsObj && Object.keys(diarySettingsObj).length > 0) {
        setSidebarCard(diarySettingsObj);
        setSidebarCardMainCopy(diarySettingsObj);
      }
      if (companyLogoURL) {
        setCompanyLogo(companyLogoURL);
      }
    } catch (e) {
      setLoader(false);
      setIsActiveVerify(false);
    }
  };

  // Scroll to View
  const initialWidth = window.innerWidth;
  const [, setScreenWidth] = useState(initialWidth);

  const handleResize = (e) => {
    const currentScreenSize = e.target.innerWidth;
    setScreenWidth(currentScreenSize);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  window.onpopstate = (event) => {
    if (
      location.search.includes("Step")
    ) {
      const stepStr = `?Step=1`;
      window.history.pushState(1, null, stepStr);
      setContext.setSetps(1);
      setPayload(initialState);
      setBackScroll(true);
      setTimeout(() => {
        setBackScroll(false);
      }, 250);
    }
  };
  const handleClose = () => {
    setLoading(false);
    setIsActive(false);
  };
  return (
    <>
      {loader && !loading ? (
        <div className="settingloader">
          <div>
            <Spinner animation="border" variant="warning" />
          </div>
        </div>
      ) : (
        <>
          <Helmet>
            <title>FCRF - Argyle Calculator</title>
          </Helmet>
          {isActive === "teste" && <PageLoader />}
          {loading && <Loader type={loaderType} handleClose={handleClose} />}
          <div className="rarity-access-page rarity-argyle-page">
            <div className="giaBack argyle_Back">
              <LeftArrowSVg />
              <p
                onClick={() => {
                  setShowArgyleContent(!showArgyleContent);
                }}
              >
                Back
              </p>
            </div>
            <div className="container argyle-section-for-pad">
              <div
                className={`rarity-access-content ${
                  steps > 1 && steps <= 5 ? "rarity-scroll-content" : ""
                }`}
                id="rarityAccessPage"
              >
                {console.log("stepssteps", steps)}
                <h2>Select the parameters for your report</h2>
                {steps >= 1 && <DiamondWeight {...props} />}
                {steps >= 2 && <DiamondColor {...props} />}
                {steps >= 3 && <DiamondShape {...props} />}
                {steps >= 4 && <DiamondSaturation {...props} />}
                {steps >= 5 && <DiamondClarity {...props} />}
              </div>
            </div>

            <div className="rarity-process-wrapper" id="processBtn">
              <div className="container">
                <div className="rarity-process-content">
                 {steps>5 && <Button
                    className="processBtn"
                    disabled={steps > 5 ? false : true}
                    onClick={handleSubmit}
                  >
                    {isActive ? <Spinner animation="border" /> : "Process"}
                  </Button>}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RarityArgyle;

const LeftArrowSVg = () => {
  return (
    <svg
      width="30"
      height="24"
      viewBox="0 0 30 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5 12H3.5"
        stroke="#D5B65A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 19L3 12L10 5"
        stroke="#D5B65A"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
