import { useContext, useEffect } from "react";
import {
  DiamondAssets,
  // DiamondColors,
  DiamondShapes,
} from "../../utils/diamondsDetail";
import { DiaryDiamondColors } from "../../utils/dairyDiamondsDetail";
import FcrfContext from "../../Context";
import { clientPath } from "../../config/key";
const DiarySaturation = ({
  saturationInfo,
  data,
  color,
  sidebarCard,
  handleSideBarCard,
  range_slider,
  isHide,
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
  const result = DiaryDiamondColors.filter((elem) =>
    data?.color === "Pink - Purplish Pink"
      ? range_slider?.colorFullName === elem.value
      : data?.color === elem.value
  );
  const showColorImg = result[0]?.img;
  const resultShape = DiamondShapes.filter(
    (elem) => data?.shape === elem.value
  );
  const showShapeImg = resultShape[0]?.img;
  const resultSaturation = DiamondAssets.filter(
    (elem) => data?.color === elem.value
  );
  let DiamondSaturation = resultSaturation[0]?.saturation || [];
  const saturationText = saturationInfo?.C30.split("#") || [];

  if (data?.saturation !== "Fancy Deep") {
    DiamondSaturation = DiamondSaturation?.filter(
      (elem) => elem !== "Fancy Deep"
    );
  }

  useEffect(() => {
    const saturationBox = {
      heading: saturationInfo?.B26,
      saturationText: saturationText?.length > 0 ? saturationText : null,
      saturation: data?.saturation || '',
      saturationTextC28:
        saturationInfo?.C28?.length > 0 ? saturationInfo?.C28 : null,
      DiamondSaturation:
        DiamondSaturation?.length > 0 ? DiamondSaturation : null,
    };
    setPdfReportData((prev) => ({ ...prev, saturationBox }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showShapeImg]);

  return (
    <section
      className={`diary-card ${sidebarCard?.saturationBox ? "show" : "hide"}`}
      id="saturationBox"
    >
      {!isHide && (
        <div className="mobile-hide-card-btn">
          <label className="diary-check-container">
            <input
              type="checkbox"
              id={`nav-saturationBox`}
              name={`saturationBox`}
              onChange={() =>
                handleSideBarCard("saturationBox", sidebarCard?.saturationBox)
              }
              checked={sidebarCard?.saturationBox}
            />
            <div className="checkmark"></div>
          </label>
        </div>
      )}
      <h4 className="title-long-width">{saturationInfo?.B26}</h4>
      <div className="diary-card-content">
        <p>
          {saturationText[0]}
          <b>{saturationText[1]}</b>
          {saturationText[2]}
          <b>{saturationText[3]}</b>
          {saturationText[4]}
          <b>{saturationText[5]}</b>
          {saturationText[6]}
          <b>{saturationText[7]}</b>
          {saturationText[8]}
          <b>{saturationText[9]}</b>
          {saturationText[10]}
        </p>
        <p>{saturationInfo?.C28}</p>
        <div className="diary-color-wrapper">
          {DiamondSaturation.map((el, i) => (
            <div className="diary-color-detail" key={i}>
              <div className="diary-saturation-img">
                {el === data?.saturation && (
                  <div className="saturation-indication">
                    <ArrowIcon color={color} />
                  </div>
                )}
                <img
                  className={`diary-saturation diamond-saturation ${el}`}
                  src={`${ clientPath }/images/diamond-${showColorImg}-general.svg`}
                  alt={`${showColorImg}-${showShapeImg}`}
                />
              </div>
              <div
                className={`diary-color-palette diamond-saturation ${el}`}
                style={{ backgroundColor: `${color}` }}
              />
              <span>
                {el} {data?.colorName}
              </span>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DiarySaturation;

const ArrowIcon = ({ color }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.4996 24.4668C17.1884 24.6446 16.9107 24.6166 16.6662 24.3828C16.4218 24.1491 16.3884 23.8771 16.5662 23.5668L19.7996 17.9002H4.56622C4.18844 17.9002 3.87155 17.7722 3.61555 17.5162C3.35955 17.2602 3.232 16.9438 3.23288 16.5669C3.23288 16.1891 3.36088 15.8722 3.61688 15.6162C3.87288 15.3602 4.18933 15.2327 4.56622 15.2335L19.7996 15.2335L16.5662 9.56687C16.3884 9.25577 16.4218 8.98327 16.6662 8.74947C16.9107 8.51577 17.1884 8.48817 17.4996 8.66687L28.1329 15.4335C28.5551 15.7002 28.7662 16.078 28.7662 16.5669C28.7662 17.0558 28.5551 17.4335 28.1329 17.7002L17.4996 24.4668Z"
        fill={color}
      />
    </svg>
  );
};
