const getLocalStorageData = () => {
    const data = {
        is_free: localStorage.getItem("is_free"),
        is_subscription: localStorage.getItem("is_subscription"),
        is_role: localStorage.getItem("is_role"),
        billing_company: localStorage.getItem("billing_company"),
        service: JSON.parse(localStorage.getItem("service")),
        item_name: localStorage.getItem("item_name"),
        product_id: localStorage.getItem("product_id"),
        type: localStorage.getItem("type"),
        active_free_account: localStorage.getItem("active_free_account"),
        is_active_free_account: localStorage.getItem("is_active_free_account"),
        subscription_id: localStorage.getItem("subscription_id"),
        custom_branding: localStorage.getItem("custom_branding"),
        is_child_access: localStorage.getItem("is_child_access"),
        rarityuser: localStorage.getItem("rarity#@user"),
    };
    
    return data;
};

export default getLocalStorageData;
