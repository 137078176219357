import { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FcrfContext from "./Context";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import RarityAccess from "./components/rarity/rarityAccess";
import RarityReport from "./components/rarityReport/rarityReportPage";
import SavedReport from "./components/savedReport/savedReportPage";
// import RarityArgyle from "./components/rarityArgyle/rarityArgylePage";
import ArgyleReport from "./components/argyleReport/argyleReportPage";
import SavedArgyleReport from "./components/savedReport/savedArgyleReportPage";
import ShareRarityReport from "./components/rarityReport/shareRarityReportPage";
import { queryString } from "./utils/queryString";
// import cookie from "js-cookie";
import ShareArgyleReport from "./components/argyleReport/shareArgyleReport";
import DiaryReportPage from "./components/diamondDiary/diaryReportPage";
import DiaryInputPage from "./components/diamondDiary/diaryInput";

import ShareDiaryReport from "./components/diamondDiary/shareDiaryReport";
import DiarySearchHistoryPage from "./components/diamondDiary/diarySearchHistoryPage";
import DiarySaveHistoryPage from "./components/diamondDiary/diarySaveHistoryPage";
import DiarySetting from "./components/setting/setting";
// import { rarityInstance, withoutAuth } from "./config/axios";
import { initialSidebar } from "./utils/sidebarMenuItems";
import axios from "axios";
import RaritySaveHistoryPage from "./components/rarityReport/raritySaveHistoryPage";
import ArgyleSavedHistoryPage from "./components/rarityArgyle/argyleSavedHistoryPage";

function App() {
  const [steps, setSetps] = useState(1);
  const [rarityColor, setRarityColor] = useState({
    color: "",
    shape: "",
  });
  const [formProgress, setFormProgress] = useState(0);
  const [isWp, setIsWp] = useState();
  const [inputError, setInputError] = useState(false);
  const [stepScroll, setStepScroll] = useState(false);
  const [backScroll, setBackScroll] = useState(false);
  const [sideBarNone, setSideBarNone] = useState("");
  const [sidebarCardId, setSidebarCardId] = useState([]);
  const [showAllCard, setShowAllCard] = useState(true);
  const [isOriginOption, setIsOriginOption] = useState("Origin - unknown");
  const [pdfReportData, setPdfReportData] = useState(null);
  const [showDiaryReportNow, setShowDiaryReportNow] = useState(false);
  const [savedNotification, setSavedNotification] = useState(false);

  const [sidebarCard, setSidebarCard] = useState(initialSidebar);
  const [sidebarCardMainCopy, setSidebarCardMainCopy] = useState(initialSidebar)
  const [activeLink, setActiveLink] = useState("GIA Summary");
  // For saving the route history
  const [prevLocation, setPrevLocation] = useState("");
  const [navigateFromPage, setNavigateFromPage] = useState("");
  const [dateSetting, setDateSetting] = useState(null);
  const[maindiary_collab_credit,setMainDiary_collab_credit]=useState(null)
  const[mainrarity_collab_credit,setMainRarity_collab_credit]=useState(null)
  const [companyLogo, setCompanyLogo] = useState('')

  //start  DEV-611
 const [is_rarity_cradits,setis_rarity_cradits]=useState(0)
 const [is_credits_included,setis_credits_included]=useState(0)
 const [is_diary_balance,setis_diary_balance]=useState(0)
//end  DEV-611



  const [isActiveVerify, setIsActiveVerify] = useState(true);
  const userLocation = ()=>{
    const geolocationSuccess = async(position)=> {
      try {
        const {
          coords: { latitude, longitude },
        } = position;
        let obj = null;
        if(!latitude || !longitude){
          localStorage.setItem('curr_location', obj);
          return;
        }
        // Get the location details.
        const location1 = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${latitude}&lon=${longitude}`);
        if(location1?.data?.address){
           obj = {
              city : location1?.data?.address?.city,
              country: location1?.data?.address?.country,
              country_code: location1?.data?.address?.country_code,
              county: location1?.data?.address?.county              ,
              state_district: location1?.data?.address?.state_district,
              postcode: location1?.data?.address?.postcode,
              suburb: location1?.data?.address?.suburb,
              state: location1?.data?.address?.state
          };
        }else{
          // Get the location details if above api don't have data.
          const location2 = await axios.get(`https://api.opencagedata.com/geocode/v1/json?q=${latitude},${longitude}&key=${process.env.REACT_APP_OPENCAGEKEY}`);
          if(location2?.data?.status.code ===200 && location2?.data?.results && location2?.data?.results.length>0){
            const locationRes = location2?.data?.results[0]?.components
             obj = {
              city : locationRes.city,
              country: locationRes.country,
              country_code: locationRes.country_code,
              county: locationRes.county              ,
              state_district: locationRes.state_district,
              postcode: locationRes.postcode,
              suburb: locationRes.suburb
            };
          };
        };
        localStorage.setItem('curr_location', JSON.stringify(obj))
      } catch (error) {
        console.log("error", error);
        localStorage.setItem('curr_location', null)
      }
      
    }

    if (window.navigator) {
      window.navigator.geolocation.getCurrentPosition(
        async (position) => {
          await geolocationSuccess(position);
        },
        (err) => {
          console.log("error in geolocation", err);
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0,
        }
      );
    }
  }

  useEffect(() => {
    if (steps === 1) {
      const progressValue = 0;
      setFormProgress(progressValue);
    } else {
      const progressValue = 20 * (steps - 1);
      setFormProgress(progressValue);
    }
  }, [steps]);

  const { token, id } = queryString();
  useEffect(() => {
    //console.log("loginauth", cookie.get("loginauth"));
    if (!token && !localStorage.getItem("rarity#@user") && !id) {
      if (window.location !== window.parent.location) {
        // The page is in an iframe
      } else {
        // The page is not in an iframe
        // window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
      };
    }
    else{
      // const fetchUserSettings = async()=>{
      //   try {
      //     const response = await rarityInstance().get(
      //       `users/setting`
      //     );
      //     if(response?.data?.status === 200){
      //       const diarySettingsObj = response?.data?.userSettings?.diarySettings;
      //       if(diarySettingsObj && Object.keys(diarySettingsObj).length > 0 ){
      //         setSidebarCard(diarySettingsObj);
      //         setSidebarCardMainCopy(diarySettingsObj);
      //       };
      //       const companyLogoURL = response?.data?.userSettings?.companylogo
      //       if(companyLogoURL){
      //         setCompanyLogo(companyLogoURL)
      //       }
      //     };
      //   } catch (error) {
      //     console.log('Error while fetching user settings---', error)
      //   }
      // };
      // fetchUserSettings();
      userLocation()
    }
  }, [token, id]);

// useEffect(()=>{
//   getlastUpdate();
// },[]);

//  const getlastUpdate = async() =>{
//   try{
//     const response = await withoutAuth().get(
//       `report/lastUpdate`
//     );
//     const {
//      data
//     } = response || {}
//     // const {
//     //   isoDate, stringDate
//     // } = data || {}
//     setDateSetting(data);
//   }catch(e){
//     console.log("e =>", e)
//   }

//  }

  //   useEffect(() => {
  //    window.addEventListener(
  //      "message",
  //      function (event) {
  //        console.log("event?.data ==>", event?.data);
  //        if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
  //          const {
  //           type
  //          } = event?.data;

  //          if (type === 'logout') {
  //            localStorage.removeItem("rarity#@user");
  //            localStorage.removeItem("is_role");
  //          }
  //        }
  //      },
  //      false
  //    );
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, []);

  return (
    <div className="App">
      <FcrfContext.Provider
        value={{
          steps: steps,
          setSetps: setSetps,
          isActiveVerify:isActiveVerify,
          setIsActiveVerify:setIsActiveVerify,
          rarityColor: rarityColor,
          setRarityColor: setRarityColor,
          formProgress: formProgress,
          setFormProgress: setFormProgress,
          isWp: isWp,
          setIsWp: setIsWp,
          inputError: inputError,
          setInputError: setInputError,
          stepScroll: stepScroll,
          setStepScroll: setStepScroll,
          backScroll: backScroll,
          setBackScroll: setBackScroll,
          sideBarNone: sideBarNone,
          setSideBarNone: setSideBarNone,
          sidebarCard: sidebarCard,
          setSidebarCard: setSidebarCard,
          sidebarCardId: sidebarCardId,
          setSidebarCardId: setSidebarCardId,
          showAllCard: showAllCard,
          setShowAllCard: setShowAllCard,
          isOriginOption: isOriginOption,
          setIsOriginOption: setIsOriginOption,
          pdfReportData: pdfReportData,
          setPdfReportData: setPdfReportData,
          showDiaryReportNow,
          setShowDiaryReportNow,
          savedNotification: savedNotification,
          setSavedNotification: setSavedNotification,
          activeLink: activeLink,
          setActiveLink: setActiveLink,
          prevLocation,
          setPrevLocation,
          navigateFromPage,
          setNavigateFromPage,
          sidebarCardMainCopy,
          setSidebarCardMainCopy,
          dateSetting, setDateSetting,
          companyLogo, setCompanyLogo,
          mainrarity_collab_credit:mainrarity_collab_credit,
          setMainRarity_collab_credit:setMainRarity_collab_credit,
          maindiary_collab_credit:maindiary_collab_credit,
          setMainDiary_collab_credit:setMainDiary_collab_credit,
          is_rarity_cradits,
          setis_rarity_cradits,
          is_credits_included,
          setis_credits_included,
          is_diary_balance,
          setis_diary_balance

        }}
      >
        <Router>
          <Routes>
            <Route exact path="/" element={<RarityAccess />} />
            <Route exact path="/report" element={<RarityReport />} />
            <Route exact path="/saved-report" element={<SavedReport />} />
            {/* <Route exact path="/argyle" element={<RarityArgyle />} /> */}
            <Route exact path="/argyle-report" element={<ArgyleReport />} />
            <Route
              exact
              path="/saved-argyle-report"
              element={<SavedArgyleReport />}
            />
            <Route
              exact
              path="/share-rarity-report/:id"
              element={<ShareRarityReport />}
            />
            <Route
              exact
              path="/share-rarity-report"
              element={<ShareRarityReport />}
            />
            <Route
              exact
              path="/share-argyle-report/:id"
              element={<ShareArgyleReport />}
            />
              <Route
              exact
              path="/share-argyle-report"
              element={<ShareArgyleReport />}
            />
            <Route exact path="/diary" element={<DiaryInputPage />} />
            <Route exact path="/diary-report" element={<DiaryReportPage />} />
            <Route exact path="/share-diary-report/:id" element={<ShareDiaryReport />} />
            <Route exact path="/sharediary" element={<ShareDiaryReport />} />
            <Route
              exact
              path="/diary-history-report"
              element={<DiarySearchHistoryPage />}
            />
            <Route
              exact
              path="/diary-save-history"
              element={<DiarySaveHistoryPage />}
            />
             <Route
              exact
              path="/rarity-save-history"
              element={<RaritySaveHistoryPage />}
            />   
            <Route
              exact
              path="/argyle-save-history"
              element={<ArgyleSavedHistoryPage />}
            />
            <Route exact path="/settings" element={<DiarySetting />} />
          </Routes>
        </Router>
      </FcrfContext.Provider>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        style={{ width: "400px" }}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
