import { useEffect, useRef, useState, useMemo, useContext } from "react";
// import { DiamondColors } from "../../utils/diamondsDetail";
import {
  DiaryDiamondColors,
  DiaryFluorescenceImage,
} from "../../utils/dairyDiamondsDetail";
import FcrfContext from "../../Context";
import { clientPath } from "../../config/key";

const DiaryFluorescence = ({
  florescenceInfo,
  data,
  sidebarCard,
  handleSideBarCard,
  isHide,
}) => {
  const { setPdfReportData } = useContext(FcrfContext);
  const fluorescenceBox = useRef(null);
  const [viewOnce, setViewOnce] = useState(false);

  const isInViewport = useIsInViewport(fluorescenceBox);

  setTimeout(() => {
    if (isInViewport) {
      setViewOnce(true);
    }
  }, 1000);

  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);

    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }
  const result = DiaryDiamondColors.filter(
    (elem) => data?.color === elem.value
  );
  const showColorImg = result[0]?.img;
  const resultShowShadow = DiaryFluorescenceImage.filter(
    (el) => el.color === florescenceInfo?.B42
  );
  const showShadow = resultShowShadow[0]?.img;

  const floreText = florescenceInfo?.C42?.split("#") || [];

  useEffect(() => {
    const fluorescenceBox = {
      heading: florescenceInfo?.B40,
      floreText: floreText?.length > 0 ? floreText : null,
      image: {
        backgroundImage: showShadow,
        img: showColorImg,
      },
    };
    setPdfReportData((prev) => ({ ...prev, fluorescenceBox }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showColorImg]);

  return (
    <section
      className={`diary-card ${sidebarCard?.fluorescenceBox ? "show" : "hide"}`}
      id='fluorescenceBox'
    >
      {!isHide && (
        <div className='mobile-hide-card-btn'>
          <label className='diary-check-container'>
            <input
              type='checkbox'
              id={`nav-fluorescenceBox`}
              name={`fluorescenceBox`}
              onChange={() =>
                handleSideBarCard(
                  "fluorescenceBox",
                  sidebarCard?.fluorescenceBox
                )
              }
              checked={sidebarCard?.fluorescenceBox}
            />
            <div className='checkmark'></div>
          </label>
        </div>
      )}

      <h4>{florescenceInfo?.B40}</h4>
      <div className='diary-card-content fluorescence-card-content'>
        <p>
          {floreText[0]}
          <b>{floreText[1]}</b>
          {floreText[2]}
          <b>{floreText[3]}</b>
          {floreText[4]} {floreText[5]}
          <b>{floreText[6]}</b>
          {floreText[7]}
        </p>
        <div
          className='fluorescence-diamond-bg'
          style={{
            backgroundImage: `${
              viewOnce ? `url(/fluorescenceImg/${showShadow}.png)` : ""
            }`,
          }}
          ref={fluorescenceBox}
        >
          <img
            // className={`fluorescence-${showShadow}`}
            src={`${clientPath}/images/diamond-${showColorImg}-general.svg`}
            alt='diamond'
          />
        </div>
      </div>
    </section>
  );
};

export default DiaryFluorescence;
