/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect, useState } from "react";
// import { Spinner } from "react-bootstrap";
// import { useLocation } from "react-router-dom";
// import LoaderIcon from "../../assets/images/diamondLoader.svg";
import "./pageLoader.css";
// import { DiaryLoaderMsg } from "../../utils/dairyDiamondsDetail";
// import { useContext } from "react";
// import FcrfContext from "../../Context";


const PageLoader = () => {
//   const wp_base_url = process.env.REACT_APP_WP_BASE_URL;
//   const location = useLocation();
  return (
    <div className="loading-screen page-loader">
      <div className="three-body">
<div className="three-body__dot"></div>
<div className="three-body__dot"></div>
<div className="three-body__dot"></div>
</div>
    </div>
  );
};

export default PageLoader;
