import { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DiaryLayout from "../layout/diaryLayout";
import FcrfContext from "../../Context";
import DiaryGIAInput from "./diaryGIAInput";
import Loader from "../loader/loader";
import { rarityInstance, withoutAuth } from "../../config/axios";
import DiaryNotice from "../Models/diaryNotice";
import PageLoader from "../loader/pageLoader";
import DisclaimerPopup from "../Models/disclaimer";
import { getChildUserInfo } from "../../utils/updateSubMetaField";
import VerticalDropdown from "../verticalDropdown/verticalDropdown";
import HeaderImagedata from "../../imageJSON/desktopImage.json";
import {
  HistoryIcons,
  SaveIcons,
  SettingsIcons,
} from "../verticalDropdown/svgData";
import getLocalStorageData from "../../utils/commonfunctions/getLocalStorageData";
import { Spinner } from "react-bootstrap";
import fetchUserSettings from "../../utils/commonfunctions/fetchUserSettings";
import getlastUpdate from "../../utils/commonfunctions/getlastUpdate";
const DiaryInputPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const getContext = useContext(FcrfContext);
  const {
    setInputError,
    setIsWp,
    setIsOriginOption,
    setMainDiary_collab_credit,
    maindiary_collab_credit,
    setMainRarity_collab_credit,
    setIsActiveVerify,
    setis_rarity_cradits,
    is_credits_included,
    setis_credits_included,
    is_diary_balance,
    setis_diary_balance,
    setCompanyLogo,
    setSidebarCard,
    setSidebarCardMainCopy,
    setDateSetting
  } = getContext;
  const initialState = {
    report_number: "",
    mine: "",
  };
  const [payload, setPayload] = useState(initialState);
  const [loading, setLoading] = useState(false);
  const [loaderType, setLoaderType] = useState("loginalert");
  const [isCheckShapeColoe, setIsCheckShapeColoe] = useState(false);
  const { from, ischeck, type } = location.state ?? {};
  const [isActive, setIsActive] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [giainputTextError, setGiainputTextError] = useState(false);
  const [deskTopBanner, setDeskTopBanner] = useState(null);
  const [mobileBanner, setMobileBanner] = useState(null);
  const [loader, setLoader] = useState(true);
  const handleGIAInput = (value, name, step) => {
    let myArr = String(value)
      .split("")
      .map((num) => {
        return Number(num);
      });
    if (myArr.length >= 7 && myArr.length <= 10) {
      setInputError(false);
      setGiainputTextError(false);
    } else if (value.length > 10) {
      setGiainputTextError(true);
      setInputError(true);
    } else {
      setGiainputTextError(false);

      setInputError(true);
    }
    setPayload({ ...payload, [name]: value });
  };
  const handleGIAMine = (e) => {
    setPayload({ ...payload, mine: e?.target?.value });
    setIsOriginOption(e?.target?.value);
  };
  const [disclaimerError, setDisclaimerError] = useState("");
  const updateDisclaimer =async () => {
    setBtnLoading(true);
    console.log("disclaimerInput", disclaimerInput);
    if (disclaimerInput) {
      const updated = await rarityInstance().put(`users/update-disclaimer`);
      if (updated.data.status === 200) {

        await handleTokenCheking(true);
        setBtnLoading(false);
        setShowDiaryDisclaimer(false);
      }
    } else {
      setDisclaimerError("Accept the disclaimer");
      setBtnLoading(false);
    }
  };
  const checkUserAcceptedDiscliamer = async () => {};

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("okkkkkkk");
    setIsActive(true);

    if (
      localStorage.getItem("rarity#@user") === "undefined" ||
      !localStorage.getItem("rarity#@user")
    ) {
      setLoading(true);
      return;
    }
    const moveForward = async () => {
      const response = await rarityInstance().get(`users/getuserinfo`);
      if (response?.data?.staus === 200) {
      await  handleTokenCheking(response?.data?.user?.diary_disclaimer_accepted);
      }

    };
    await checkUserAcceptedDiscliamer();
    if (!localStorage.getItem("rarity#@user")) {
      setTimeout(async () => {
        moveForward();
      }, 3000);
    } else {
      moveForward();
    }
  };
  const [showDiaryDisclaimer, setShowDiaryDisclaimer] = useState(false);
  const [disclaimerInput, setDisclaimerInput] = useState(false);

  const handleTokenCheking = async (disclaimerValue) => {
    try {
      setIsActive(true);
      var { is_child_access,subscription_id } = getLocalStorageData();
      const is_child_access2 = is_child_access;
      const child_access2 = JSON.parse(is_child_access2);
      const is_a_child_user2 = child_access2?.is_a_child_user || {};
      const cUserID = child_access2?.curr_user_id || "";
      const pSubId = child_access2?.parent_subscription_id || "";
      const subscriptionId =
        is_a_child_user2 === true ? pSubId : subscription_id;
      console.log("is_child_access main ===>", subscriptionId, cUserID);
      if(child_access2?.is_a_child_user ){
       const {rarity_credits,credits_included,diary_balance}= await getChildUserInfo(subscriptionId, cUserID);
       setis_credits_included(credits_included)
       setis_diary_balance(diary_balance)
       setis_rarity_cradits(rarity_credits)
      }
      var { is_role, item_name,is_subscription,product_id } = getLocalStorageData();
      const child_access = JSON.parse(getLocalStorageData().is_child_access);
      const {
        is_a_child_user,
        parent_subscription_status,
        credits_included,
        diary_balance,
        parent_user_id,
        curr_user_id,
      } = child_access || {};
      var { is_active_free_account,rarityuser } = getLocalStorageData();
      if (!rarityuser) {
        setLoading(true);
        setIsActive(true);
        return;
      }
      const parentUserId =
        is_a_child_user === true ? parent_user_id : curr_user_id;
      const res = await rarityInstance().get(
        `diamond/get-report-count?reportNumber=${payload.report_number}&pWpUserId=${parentUserId}`,
      );
      const { code, isOldReportFound } = res.data || {};
      console.log("code and found =>", { code, isOldReportFound });

      if (is_role === "administrator" || is_role === "contributor") {
        setShowDiaryDisclaimer(!disclaimerValue);
        setIsActive(false);
        if (!disclaimerValue) return;
        navigate("/diary-report", {
          state: {
            filter: payload,
            action: "Create",
            from: "diary",
            isOldReportFound: isOldReportFound,
          },
        });
      } else if (is_role === "industry") {
        if (
          maindiary_collab_credit === "-1" ||
          maindiary_collab_credit > 0 ||
          isOldReportFound
        ) {
          setShowDiaryDisclaimer(!disclaimerValue);
          setIsActive(false);
          if (!disclaimerValue) return;
          navigate("/diary-report", {
            state: {
              filter: payload,
              action: "Create",
              from: "diary",
              isOldReportFound: isOldReportFound,
            },
          });
          return;
        } else {
          setLoaderType("is_branding");
          console.log("is_member");
          setLoading(true);
          return;
        }
      } else if (is_role === "data_supplier") {
        if (is_subscription === "active" && subscription_id) {
          setShowDiaryDisclaimer(!disclaimerValue);
        setIsActive(false);
        if (!disclaimerValue) return;
          navigate("/diary-report", {
            state: {
              filter: payload,
              action: "Create",
              from: "diary",
              isOldReportFound: isOldReportFound,
            },
          });
          return;
        } else {
          setLoaderType("is_data_supplier");
          console.log("is_member");
          setLoading(true);
          return;
        }
      } else {
        const productID = [
          process.env.REACT_APP_FREE,
          process.env.REACT_APP_MONTH,
          process.env.REACT_APP_ASS,
          process.env.REACT_APP_PRO,
          process.env.REACT_APP_PRONEW,
          process.env.REACT_APP_PROCOP,
          process.env.REACT_APP_PROCON,
          process.env.REACT_APP_IND,
        ];

        if (is_a_child_user && parent_subscription_status === "active") {
          if (
            credits_included === "-1" ||
            credits_included > 0 ||
            diary_balance > 0 ||
            isOldReportFound
          ) {
            setShowDiaryDisclaimer(!disclaimerValue);
            setIsActive(false);
            if (!disclaimerValue) return;
            navigate("/diary-report", {
              state: {
                filter: payload,
                action: "Create",
                from: "diary",
                isOldReportFound: isOldReportFound,
              },
            });
            return;
          } else {
            console.log("contact to parent ");
            setLoaderType("is_search_up_child");
            setLoading(true);
            return;
          }
        }

        if (
          is_a_child_user === true &&
          parent_subscription_status !== "active"
        ) {
          console.log("not okkk ==>");
          setLoaderType("is_member");
          console.log("is_member");
          setLoading(true);
          return;
        }
        console.log("productID.includes(product_id) =>", product_id, productID);
        if (productID.includes(product_id) && product_id) {
          if (is_role === "brands" || is_role === "industry") {
            setLoaderType("is_branding");
            console.log("is_member");
            setLoading(true);
            return;
          }
          if (
            !!(
              is_role === "individual" ||
              product_id === process.env.REACT_APP_IND
            )
          ) {
            setLoaderType("is_member");
            console.log("is_member");
            setLoading(true);
            return;
          }

          if (is_subscription !== "active") {
            setLoaderType("is_expireddiary");
            console.log("is_member22");
            setLoading(true);
            return;
          }
          // free account active check
          if (
            product_id === process.env.REACT_APP_FREE &&
            is_active_free_account === "false"
          ) {
            setLoaderType("is_free_status");
            setLoading(true);
            return;
          }

          if (
            product_id === process.env.REACT_APP_FREE &&
            is_active_free_account === "true" &&
            parseInt(is_credits_included) <= 0 &&
            is_credits_included !== "-1"
          ) {
            console.log("limit  cjjcjcjc");
            setLoaderType("is_free");
            setLoading(true);
            return;
          }
          if (
            item_name === "" ||
            product_id === "" ||
            item_name === null ||
            product_id === null
          ) {
            setLoaderType("is_limit");
            console.log("is_member33");
            setLoading(true);
            return;
          }

          if (is_credits_included === "-1") {
            setShowDiaryDisclaimer(!disclaimerValue);
            setIsActive(false);
            if (!disclaimerValue) return;
            navigate("/diary-report", {
              state: {
                filter: payload,
                action: "Create",
                from: "diary",
                isOldReportFound: isOldReportFound,
              },
            });
          }
          console.log({
            product_id,
            is_active_free_account,
          });

          if (!isOldReportFound) {
            console.log(isOldReportFound);
            if (
              parseInt(is_credits_included) === 0 &&
              parseInt(is_diary_balance) <= 0
            ) {
              console.log(" is_search_up  =========>");
              setLoaderType("is_search_up");
              setLoading(true);
              return;
            }
          }
          setShowDiaryDisclaimer(!disclaimerValue);
          setIsActive(false);
          if (!disclaimerValue) return;
          setIsActive(false);
          navigate("/diary-report", {
            state: {
              filter: payload,
              action: "Create",
              from: "diary",
              isOldReportFound: isOldReportFound,
            },
          });
        } else {
          setLoaderType("is_member");
          console.log("is_member");
          setLoading(true);
          return;
        }
      }
    } catch (e) {
      console.log("Error in submit", e);
    }
  };

useEffect(() => {

  if (loader) {
//     const token =
//     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo3NTYsInRpbWUiOjE3MTUzMzM2NTV9.j-sbzDOAZjCowM48mdqXNUQ8O74uUa1rOKMaUSWfv18";
//     getVerifyUser(token);
// return;
    const interval = setInterval(() => {
      window.parent.postMessage("iframeIsReadyToReceiveUserData", '*');
    }, 1000);

    const handleMessage = event => {
      setIsActiveVerify(true);
      console.log("event.origin", event.origin);
      console.log("event.origin2", process.env.REACT_APP_WP_BASE_URL);
      if (event.origin === process.env.REACT_APP_WP_BASE_URL) {
        const {
          is_free,
          token,
          status,
          role,
          billing_company,
          is_wp,
          activeserviceArr,
          item_name,
          product_id,
          type,
          active_free_account,
          is_active_free_account,
          is_rarity_cradits,
          is_credits_included,
          is_diary_balance,
          subscription_id,
          custom_branding,
          diary_collab_credit,
          rarity_collab_credit,
          is_child_access,
        } = event?.data;

        setIsWp(is_wp);
        console.log("token come form wp diary==>", token);
        console.log("event?.data==>", event?.data);

        if (token) {
          setMainDiary_collab_credit(diary_collab_credit);
          setMainRarity_collab_credit(rarity_collab_credit);
          setis_diary_balance(is_diary_balance);
          setis_credits_included(is_credits_included);
          setis_rarity_cradits(is_rarity_cradits);
          setIsActiveVerify(false);
          localStorage.setItem("is_free", is_free);
          localStorage.setItem("is_subscription", status);
          localStorage.setItem("is_role", role);
          localStorage.setItem("billing_company", billing_company);
          localStorage.setItem("service", JSON.stringify(activeserviceArr));
          localStorage.setItem("item_name", item_name);
          localStorage.setItem("product_id", product_id);
          localStorage.setItem("type", type);
          localStorage.setItem("active_free_account", active_free_account);
          localStorage.setItem(
            "is_active_free_account",
            is_active_free_account
          );
          localStorage.setItem("subscription_id", subscription_id);
          localStorage.setItem("custom_branding", custom_branding);
          localStorage.setItem("is_child_access", is_child_access);
          getVerifyUser(token);
        } else {
          localStorage.removeItem("rarity#@user");
        }
      } else {
        if (!localStorage.getItem("rarity#@user")) {
          if (window.location !== window.parent.location) {
            // The page is in an iframe
          } else {
            // The page is not in an iframe
            window.location.href = process.env.REACT_APP_WP_APP_LOGIN;
          }
        }
      }

      if (event?.data) {
        clearInterval(interval);
      }
    };

    window.addEventListener("message", handleMessage, false);

    return () => {
      window.removeEventListener("message", handleMessage);
      clearInterval(interval);
    };
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [location, loader]);



  const getVerifyUser = async (token) => {
    try {
      const response = await withoutAuth().post("users/verifyUser", {
        tokenid: token,
      });
      console.log("response", response);
      const {
        token: usertoken,
        statusofsub,
        role,
        activeservice,
        billing_company,
        item_name,
        product_id,
        type,
        diaryattempted,
        rarityattempted,
        argyleattempted,
        active_free_account,
        is_active_free_account,
        is_rarity_cradits,
        is_credits_included,
        is_diary_balance,
        subscription_id,
        custom_branding,
        diary_collab_credit,
        rarity_collab_credit,
        is_child_access,
      } = response?.data || {};
      console.log("is_child_access ======>", is_child_access);
      localStorage.setItem("rarity#@user", usertoken);
      setMainDiary_collab_credit(diary_collab_credit);
      setMainRarity_collab_credit(rarity_collab_credit);
      setis_diary_balance(is_diary_balance)
      setis_credits_included(is_credits_included)
      setis_rarity_cradits(is_rarity_cradits)
      setIsActiveVerify(false);
      localStorage.setItem("is_subscription", statusofsub);
      localStorage.setItem("is_role", role);
      localStorage.setItem("billing_company", billing_company);
      localStorage.setItem("service", JSON.stringify(activeservice));
      localStorage.setItem("item_name", item_name);
      localStorage.setItem("product_id", product_id);
      localStorage.setItem("type", type);
      localStorage.setItem("dairyattemptedcount", diaryattempted);
      localStorage.setItem("attemptedcount", rarityattempted);
      localStorage.setItem("argyleattempted", argyleattempted);
      localStorage.setItem("active_free_account", active_free_account);

      localStorage.setItem("is_active_free_account", is_active_free_account);
      localStorage.setItem("subscription_id", subscription_id);
      localStorage.setItem("custom_branding", custom_branding);
      localStorage.setItem("is_child_access", is_child_access);
      setLoader(false);
      console.log("responcceersr", response);
     const { diarySettingsObj, companyLogoURL}=await fetchUserSettings()
     const {data}=await getlastUpdate()
     if(data){
      setDateSetting(data);
     }
     if(diarySettingsObj && Object.keys(diarySettingsObj).length > 0 ){
      setSidebarCard(diarySettingsObj);
      setSidebarCardMainCopy(diarySettingsObj);
    };
     if(companyLogoURL){
      setCompanyLogo(companyLogoURL)
    }
    } catch (e) {
      setLoader(false);
      setIsActiveVerify(false);
    }
  };

  const props = {
    payload,
    handleGIAInput,
    handleSubmit,
    handleGIAMine,
    isActive,
    giainputTextError,
  };

  useEffect(() => {
    if (from === "diaryreport" && ischeck) {
      setIsCheckShapeColoe(true);
      navigate("/diary", {
        state: { from: "", ischeck: false, type },
      });
    }
  }, [from, ischeck, navigate, type]);

  const handleClose = () => {
    setLoading(false);
    setIsActive(false);
  };
  const handleCheckLogin = () => {
    console.log("okkk login no");
    setLoading(true);
    return;
  };
  const dropdownItems = [
    {
      icon: HistoryIcons,
      route: "/diary-history-report",
      label: "Search History",
    },
    { icon: SaveIcons, route: "/diary-save-history", label: "Saved Reports" },
    { icon: SettingsIcons, route: `/settings`, label: "Report Settings" },
  ];

  const [accordianShow, setAccordianShow] = useState(false);

  const accordianOpen = () => {
    setAccordianShow(true);
  };
  const accordianClose = () => {
    setAccordianShow(false);
  };

  useEffect(() => {
    const desktopImages = HeaderImagedata?.desktopimages;
    const mobileImages = HeaderImagedata?.mobileimages;
    if (desktopImages.length > 0) {
      const randomDesktopImage =
        desktopImages[Math.floor(Math.random() * desktopImages.length)];
      setDeskTopBanner(randomDesktopImage);
    }

    if (mobileImages.length > 0) {
      const randomMobileImage =
        mobileImages[Math.floor(Math.random() * mobileImages.length)];
      setMobileBanner(randomMobileImage);
    }
  }, []);

  return (
    <>

      {loader && !loading && !isCheckShapeColoe && !showDiaryDisclaimer? (
        <div className="settingloader">
          <div>
            <Spinner animation="border" variant="warning" />
          </div>
        </div>
      ) : (
        <>
          {isActive === "teste" && <PageLoader />}
          <DiaryLayout
            sideBar={false}
            header={true}
            from="inputpage"
            handleCheckLogin={handleCheckLogin}
            className="header-remove-mob"
          >
            {loading && (
              <Loader
                type={loaderType}
                handleClose={handleClose}
                from="diary"
              />
            )}
            <div className="diary-input-page">
              <div className="container">
                <div className="rarity-access-content  diary-input-content diary-input-mob">
                  <div className="diary-title diary-title-mt w-100">
                    <h1>
                      Diamond diary<span className="sup-text">™</span>{" "}
                      <span className="beta-top">[BETA]</span>
                    </h1>
                    <VerticalDropdown
                      handleCheckLogin={handleCheckLogin}
                      dropdownItems={dropdownItems}
                    />
                  </div>
  
                  <div
                    className="w-100 dairy-banner dairy-banner-shadow"
                    style={{
                      backgroundImage: `url(${deskTopBanner ? deskTopBanner : "/diaryImage/desktop-header-1.jpg"})`,
                    }}
                  >
                    <h1>
                      <span>present</span> the story not just a gem
                    </h1>
                  </div>
                  <div
                    className="w-100 dairy-banner diary-mob-banner dairy-banner-shadow"
                    style={{
                      backgroundImage: `url(${mobileBanner ? mobileBanner : "/diaryImage/diamond-diary-page-mobile-photo1.jpg"})`,
                    }}
                  >
                  <h1>
                      <span>present</span> the story not just a gem
                    </h1>
                  </div>
                  <DiaryGIAInput {...props} />
                  <div className="diary-input-desc diary-input-desc-pd  w-100">
                    {!accordianShow && (
                      <div
                        onClick={accordianOpen}
                        className="accordian-down-header"
                      >
                        <ArrowSvg />
                        <p>About The Diary Report</p>
                      </div>
                    )}
                    {accordianShow && (
                      <div>
                        <p>
                          The Diamond Diary report was created to showcase the
                          unique story of a fancy color diamond, complete with
                          images and information about the mine, rarity, weight to
                          face-up ratio, iconic figures associated with similar
                          diamonds, and much more. This report's data output is the
                          result of years of experience and serves as an additional
                          layer of information for evaluating fancy color diamonds.
                          Above all, retailers use the Diary as a talking-points
                          report that provides relevant information to fancy color
                          diamond collectors. The Diary's primary goal is to help
                          retailers demonstrate professionalism and achieve trust.
                        </p>
                        <div className="arrow-up" onClick={accordianClose}>
                          <ArrowSvg />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <DiaryNotice
              show={isCheckShapeColoe}
              setShow={setIsCheckShapeColoe}
              type={type}
              reportType="diary"
            />
          </DiaryLayout>
          {showDiaryDisclaimer && (
            <DisclaimerPopup
              show={showDiaryDisclaimer}
              setShow={setShowDiaryDisclaimer}
              disclaimerInput={disclaimerInput}
              setDisclaimerInput={setDisclaimerInput}
              updateDisclaimer={updateDisclaimer}
              disclaimerError={disclaimerError}
              btnLoading={btnLoading}
            />
          )}
        </>
      )}
    </>
  );
  
};

export default DiaryInputPage;

const ArrowSvg = () => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 109.28 90.9"
    >
      <path d="M89.6,21.81a3.69,3.69,0,0,0-3.21-5.53l-31.76.11-31.77.11a3.69,3.69,0,0,0-3.17,5.55l16,27.45L48,70.72a7.86,7.86,0,0,0,13.61,0l12.2-21.3Z" />
    </svg>
  );
};
