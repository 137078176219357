/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from "react";
import Loader from "../loader/loader";
import RarityToolCard from "./rarityToolCard";
import DiamondCharacteristics from "./diamondCharacteristics";
import GemologicalCharacteristics from "./gemologicalCharacteristics";
import { withoutAuth } from "../../config/axios";
import { resultFormating } from "../../utils/resultFormating";
import { queryString } from "../../utils/queryString";
import { Helmet } from "react-helmet";
import { getFormattedDate } from "../../utils/dateUtils";
import { clientPath, wpPath } from "../../config/key";
// import { DiamondColors } from "../../utils/diamondsDetail";
import { DiaryDiamondColors, DiaryDiamondShapes } from "../../utils/dairyDiamondsDetail";
import { useLocation, useParams } from "react-router-dom";
import { decryptString, extractShareIdByKey } from "../../utils/decryptText";
import RarityHighlightedBox from "./rarityHighlightedBox";
import RarityInfo from "./rarityInfo";
import "./rarityReport.css";
import LogowithColor from "../../assets/images/Logo-with-color.svg";
import ScrollToTop from "../common/scrolltotop";
const ShareRarityReport = () => {
  const { id } = queryString();
  const [loading, setLoading] = useState(true);
  const [characteristics, setCharacteristics] = useState({});
  // const [des_texts, setDisText] = useState({});
  const [bubble_texts, setBubbleText] = useState({});
  const [range_slider, setRangeSlider] = useState({});
  const [labelName, setLabelName] = useState([]);
  const [tooltip_text, setTooltipText] = useState([]);
  const [labelValue, setLabelValue] = useState([]);
  const[animationText,setAnimationText]=useState(null)
  // const [readmore, setReadmore] = useState(false);
  const [reportData, setReportData] = useState({});
  const [time, setTime] = useState(null);
  const [imgScaleVal, setImgScaleVal] = useState(1);
  const [metaImgData, setMetaImgData] = useState({
    color:"",
    shape:""
  })
  const sharerarityIds = useParams();
  const [companyLogo, setCompanyLogo] = useState("");
  const [custom_branding, setCustomBranding] = useState("0");
  // const [isButtonVisible, setIsButtonVisible] = useState(false);
  const location = useLocation();


  useEffect(() => {
    async function fetchData(_id) {
      const {postId,createdBy} = await getReportById(_id);
      await sharePageLoaded(postId,createdBy);
    }
    const params = location.search;
    // Check for the legacy link
    if (params?.includes("?id")) {
      const decData = decryptString(id);
      const shareIdvalue = extractShareIdByKey(decData, "Id");
      fetchData(shareIdvalue);
    } else {
      // Check for the updated link
      const rarityPayload = { id: sharerarityIds?.id };
      fetchData(rarityPayload.id);
    }
  }, [id]);

  // scroll up
  // const handleScroll = () => {
  //   const scrollY = window.scrollY;
  //   if (scrollY > 100) {
  //     setIsButtonVisible(true);
  //   } else {
  //     setIsButtonVisible(false);
  //   }
  // };

  // const scrollToTop = () => {
  //   window.scrollTo({
  //     top: 0,
  //     behavior: "smooth",
  //   });
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // for read more
  let fullLoremIpsumText =
    "Fancy Color Diamond appreciation revolves around beauty and rarity. The Rarity Report illustrates the market prevalence of a polished diamond. Whether a diamond is one of several hundred or is introduced to the market once a decade, rarity was, and always will be, a part of the epic story of a fancy color diamond."
  const truncatedTextLength = 113;
  const [showFullText, setShowFullText] = useState(false);
  const [displayText, setDisplayText] = useState(
    fullLoremIpsumText.substring(0, truncatedTextLength)
  );
  const toggleReadMore = useCallback(() => {
    setShowFullText(!showFullText);
    setDisplayText(
      showFullText
        ? fullLoremIpsumText.substring(0, truncatedTextLength)
        : fullLoremIpsumText
    );
  });
  const AnimateStringData = (inputString) => {
    let Text = inputString?.split("#") || [];
    const parts = Text[1]?.split("~");

    if (parts?.length === 2) {

      const secondString = parts[1].trim().replace(/,/g,"")
      console.log(secondString);
      setAnimationText(Number(secondString))
      return secondString;
    } else {
      setAnimationText(null)
      return null;
    }
  };
  const sharePageLoaded = async (postId,createdBy) => {
    try {
      // const decData = decryptString(id);
      // const { postId } = decData || {};
      await withoutAuth().post("report/share-link-open/Rarity", {
        reportId: postId,
        createdBy,
        shareUrl: window.location.href,
      });
    } catch (error) {
      console.error("error in open link===", error);
    }
  };

  const getReportById = async (id) => {
    try {
      const response = await withoutAuth().post("report/getreportbyId", {
        id,
      });
      const { getReport, companyInfo,createdBy} = response?.data;
      const reportData = getReport?.report || {};
      // setReportData(reportData);
      if (reportData) {
        // des_texts,
        const {  data, graph_texts, bubble_texts, range_slider } =
          reportData;
        const { labelname, graph_tooltip_text, labelvalue } = resultFormating(
          graph_texts,
          range_slider,
          data
        );
       
        const times = getFormattedDate(reportData.timestamp);
        setCompanyLogo(companyInfo?.companyLogo || "")
        setCustomBranding(companyInfo?.customBranding || "0")
        setReportData(reportData);
        setLabelName(labelname);
        setLabelValue(labelvalue);
        setTooltipText(graph_tooltip_text);
        // setDisText(des_texts);
        // setGrapgText(graph_texts);
        setBubbleText(bubble_texts);
        AnimateStringData(bubble_texts?.D18)
        setRangeSlider(range_slider);
        setCharacteristics(data);
        setTime(times);

        const Imgshape= DiaryDiamondShapes.filter((elem) => getReport?.report?.data?.shape === elem.value)
        const imgColorShape = DiaryDiamondColors.filter((elem) => getReport?.report.data?.color === elem.value);
        setMetaImgData({color:imgColorShape[0]?.img , shape: Imgshape[0]?.img})

      }
      setLoading(false);
      return ({postId:reportData?.postId,createdBy});
    } catch (error) {
      console.log("error", error);
    }
  };

  // const color = getColorCode(labelName[0]);
  // const handleReadMore = () => {
  //   setReadmore(!readmore);
  // };

  const sizeValueInit =
    reportData?.gia_report?.results?.data?.measurements?.length /
      reportData?.gia_report?.results?.data?.measurements?.width || 0;

  const sizeValue =
    Number(sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) || 0;

  console.log("sizeValueInit>>>", sizeValueInit);
  console.log("sizeValue>>>", sizeValue);

  useEffect(() => {
    if (
      reportData?.data?.shapeCategory === "Cushion" ||
      reportData?.data?.shapeCategory === "Radiant"
    ) {
      if (sizeValue <= 1.05) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
        setImgScaleVal(1.075);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(1.15);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(1.225);
      } else if (sizeValue >= 1.35) {
        setImgScaleVal(1.3);
      }
    } else if (reportData?.data?.shapeCategory === "Emerald") {
      if (sizeValue <= 1.14) {
        setImgScaleVal(0.775);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(0.85);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(0.925);
      } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.45) {
        setImgScaleVal(1.075);
      }
    }
  }, [sizeValue]);

  const formatDateReport = (input) => {
    const dateObject = new Date(input);
    if (isNaN(dateObject.getTime())) {
      return 'Invalid Date';
    }
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const lastTwoDigitsOfYear=year % 100;
    const formattedDate = `${day}.${month}.${lastTwoDigitsOfYear}`;
    return formattedDate;
  };
  const formatDate = (inputDate) => {
    const dateComponents = inputDate?.match(/(\w+) (\d+)(?:st|nd|rd|th), (\d+)/);
    if (!dateComponents) {
      return 'Invalid Date';
    }
    const months = {
      January: 0,
      February: 1,
      March: 2,
      April: 3,
      May: 4,
      June: 5,
      July: 6,
      August: 7,
      September: 8,
      October: 9,
      November: 10,
      December: 11,
    };
    const month = months[dateComponents[1]];
    const day = dateComponents[2];
    const year = dateComponents[3];
    const date = new Date(year, month, day);
    const lastTwoDigitsOfYear=year % 100;
    const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${lastTwoDigitsOfYear}`;
    return formattedDate;
  };

  return (
    <>
      <Helmet>
        <title>FCRF - Rarity Report</title>
        {/* <meta name="description" content="Shared Rarity Report" /> */}
        <meta property="og:title" content="Rarity Report"/>
        <meta property="og:description" content="your Rarity Report"/>
        {/* <meta property="og:image" content="https://dashboard-dev.fcresearch.org/images/diamond-yellow-general.svg" /> */}
        <meta property="og:image" content={`${clientPath}/images/diamond-${metaImgData.color}-${metaImgData.shape}.svg`} />
      </Helmet>
      {loading && <Loader type={"progress"} />}

      <div className="rarity-report-page shared-rarity-report-page">
        <div className="rarity-report-head"></div>
        <div className="rarity-report-content">
          <div className="container">
            <div className="rarity-tool">
              <div className="rarity-tool-grid">
                <div className="rarity-tool-left">
                  <div className="rarity-heading-wrapper card-pd grade-mob-view">

                    <div className="share-rarity-header">
                    <div className="cursor-pointer" onClick={()=>{
                       window.open(wpPath, '_blank');
                    }}>
<img
             className="rarity-sharePage-logo"
                src={LogowithColor}
                alt="FCRF logo"
              />
                  
                    </div>
                    <h5 className="rarity-date">{time}</h5>
                    <h1>
                      The Rarity Report
                    </h1>
                  </div>
                  <div className="rarity-time-report-no-wrapper grade-mob-view mb-2">
                     
                    </div>
                    <div>
                     
                      {companyLogo && custom_branding === "1" && (
                        <>
                          <p className="m-0">Issued for:</p>
                          <div
                            style={{
                              width: "120px",
                              height: "50px",
                              marginTop: "0px",
                            }}
                          >
                            <img
                              src={companyLogo}
                              alt="comp_logo"
                              style={{
                                height: "100%",
                                width: "100%",
                                objectPosition: "left",
                                objectFit: "contain",
                                marginTop: "0px",
                              }}
                            />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="rarity-heading-wrapper card-pd grade-desktop-view share-rarity">
                    <div className="cursor-pointer" onClick={()=>{
                       window.open(wpPath, '_blank');
                    }}>

          <img
             className="rarity-sharePage-logo"
                src={LogowithColor}
                alt="FCRF logo"
              />
                    </div>
                    <h1>
                      The Rarity Report
                    </h1>
                  </div>
                  <div className="card-pd">
                    <DiamondCharacteristics
                      report={characteristics}
                      loading={false}
                      imgScaleVal={imgScaleVal}
                      range_slider={range_slider}
                    />
                  </div>
                  <div className="grade-mob-view">
                    <RarityHighlightedBox
                      range_slider={range_slider}
                      cardDesc={bubble_texts?.H18}
                      animationText={animationText}
                    />
                  </div>

                  <div
                    className={`${
                      characteristics.report_number === "undefined" ||
                      characteristics.report_number === undefined ||
                      characteristics.report_number === ""
                        ? "giaNum-mt"
                        : "giaNum-mt1"
                    }`}
                  >
                    <RarityHighlightedBox animationText={animationText} cardDesc={bubble_texts?.D18} />
                  </div>
                  <div className="grade-mob-view card-pd">
                    <RarityToolCard
                      cardDesc={displayText}
                      loading={false}
                      line={2}
                      showFullText={showFullText}
                      toggleReadMore={toggleReadMore}
                      className="rarity-left-content-alignment"
                      readMore={true}
                    />
                  </div>
                  <div className="card-pd">
                    <GemologicalCharacteristics
                      graph_texts={tooltip_text}
                      labelValue={labelValue}
                      labelName={labelName}
                      loading={false}
                    />
                  </div>
                  <div className="mobile-rarity-customize-bottom-png">
                      <span><img src="/images/fcrfbottom.svg"  alt="/images/fcrfbottom.svg"/></span>
                      <p>fcresearch.org</p>
                    </div>
                  <div className="rarity-tool-bottom-text-padding mt-auto">
                  {reportData?.data?.giaNumber === "" && 
                    <p className="rarity-tool-text mb-1">The rarity of the diamond is based on the diamond's GIA graded parameters.</p>
                    }
                    <p className="rarity-tool-text mb-2">
                      The data acquired from The Fancy Color Diamond Rarity
                      Report©. may change from time to time and is not a
                      guarantee nor a reflection of the diamond’s value. For
                      additional information regarding the data, and other
                      limitations, contact us at: customers@fcresearch.org
                    </p>
                    
                    

                    <p className="report-timestamp   rarity-report-time-stamp grade-mob-view">

                      <span>Report generated on { formatDateReport(reportData?.timestamp)} </span>
                      <br/>
                      <span> Last data update on {formatDate(reportData?.textSheetData?.H12)}</span>
                    </p>
                    {
                      console.log("Updated data",reportData?.textSheetData?.H12)
                    }
                   <p className="report-timestamp rarity-report-time-stamp grade-desktop-view ">
                      Report generated on {formatDateReport(reportData?.timestamp) } |
                      Last data update on {formatDate(reportData?.textSheetData?.H12)}
</p>


                  </div>
                </div>
                <div className="rarity-tool-right">
                  <div
                    className={`rarity-time-report-no-wrapper  grade-desktop-view ${
                      companyLogo && custom_branding === "1"
                        ? "rarity-img-pd p-0 "
                        : "rarity-space"
                    }`}
                  >
                    <h5>{time}</h5>
                    <p>Report {reportData?.postId}</p>
                    {companyLogo && custom_branding === "1" && (
                      <div>
                        <p>Issued for:</p>
                        <div
                          style={{
                            width: "150px",
                            height: "50px",
                            marginTop: "0px",
                          }}
                        >
                          <img
                            src={companyLogo}
                            alt="comp_logo"
                            style={{
                              height: "100%",
                              width: "100%",
                              objectPosition: "left",
                              objectFit: "contain",
                              marginTop: "-5px",
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="grade-desktop-view rarity-img-mt">
                    <RarityToolCard
                      cardDesc={
                        "Fancy Color Diamond appreciation revolves around beauty and rarity. The Rarity Report illustrates the market prevalence of a polished diamond. Whether a diamond is one of several hundred or is introduced to the market once a decade, rarity was, and always will be, a part of the epic story of a fancy color diamond."
                      }
                      loading={false}
                      line={2}
                      className="rarity-left-content-alignment"
                    />
                  </div>
                  <div className={`grade-desktop-view ${characteristics.report_number?"share-rarity-grade":"share-rarity-mt"}`}>
                    <RarityHighlightedBox
                      range_slider={range_slider}
                      cardDesc={bubble_texts?.H18}
                      animationText={animationText}
                    />
                  </div>

                  <RarityInfo  range_slider={range_slider}/>
                  <div className="rarity-customize-bottom-png">
                      <span><img src="/images/fcrfbottom.svg" alt="FCRF" /></span>
                      <p>fcresearch.org</p>
                    </div>
                </div>
              </div>

              <div className="report-action-footer ">
                <div className="rarity-header-section grade-mob-view"></div>
              </div>

              <ScrollToTop scrollClass={"scroll-up-share"}/>

              {/* <div
                className={`scroll-up grade-mob-view ${isButtonVisible?"":"d-none"}`}
              >
                <span onClick={scrollToTop}>
                  {" "}
                  <ArrowUp />
                </span>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareRarityReport;

// const ArrowUp = () => {
//   return (
//     <svg
//       width="42"
//       height="42"
//       viewBox="0 0 42 42"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g filter="url(#filter0_d_626_167)">
//         <circle cx="21" cy="17" r="17" fill="#171F37" />
//         <path
//           d="M22.2664 24.3335H20.2462V12.2123L14.6907 17.7678L13.2563 16.3335L21.2563 8.3335L29.2563 16.3335L27.822 17.7678L22.2664 12.2123V24.3335Z"
//           fill="#BF8F02"
//         />
//       </g>
//       <defs>
//         <filter
//           id="filter0_d_626_167"
//           x="0"
//           y="0"
//           width="42"
//           height="42"
//           filterUnits="userSpaceOnUse"
//           color-interpolation-filters="sRGB"
//         >
//           <feFlood flood-opacity="0" result="BackgroundImageFix" />
//           <feColorMatrix
//             in="SourceAlpha"
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
//             result="hardAlpha"
//           />
//           <feOffset dy="4" />
//           <feGaussianBlur stdDeviation="2" />
//           <feComposite in2="hardAlpha" operator="out" />
//           <feColorMatrix
//             type="matrix"
//             values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
//           />
//           <feBlend
//             mode="normal"
//             in2="BackgroundImageFix"
//             result="effect1_dropShadow_626_167"
//           />
//           <feBlend
//             mode="normal"
//             in="SourceGraphic"
//             in2="effect1_dropShadow_626_167"
//             result="shape"
//           />
//         </filter>
//       </defs>
//     </svg>
//   );
// };
