import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../footer/footer";
import FcrfContext from "../../Context";
import leftArrow from "../../assets/images/rarity/icon-arrow-left.svg";
import { queryString } from "../../utils/queryString";

const Layout = ({ header,children,className , activeTab , showArgyleContent }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const getContext = useContext(FcrfContext);
  const setContext = useContext(FcrfContext);
  const { steps,setFormProgress } = getContext;
  const { pId, from } = queryString();
  //console.log("locationnnnnnn=>", location , "/argyle-report")
  // const { action, filter, id } = location?.state || {};
  //console.log("location?.state====>", location, "/argyle")
  const backPage = window.location.search;

  const handleOpen = () => {
    if (location.pathname === "/report") {
      return navigate("/saved-report?report");
    }
    if (location?.pathname === "/argyle-report") {
      return navigate("/saved-argyle-report?argyle-report");
    }
    if (location?.pathname === "/" && activeTab==="tab2") {
      return navigate("/saved-argyle-report");
    }
    navigate("/saved-report");
  };

  // const handleBack = () => {
  //   if (location.pathname === "/report") {
  //     navigate("/");
  //     window.location.reload();
  //   } else {
  //     window.history.back();
  //   }
  // };

  // const handleBackRairity = () => {
  //   if (steps === 1) {
  //     navigate("/");
  //   }
  //   if (steps > 1) {
  //     const nextStep = steps - 1;
  //     setContext.setSetps(nextStep);
  //     if (nextStep === 1) {
  //       setPayload({ ...payload, weight: "" });
  //       window.history.pushState("1", null, "/");
  //       const progressValue = 20 * nextStep;
  //       setFormProgress(progressValue);
  //     } else if (nextStep === 2) {
  //       window.history.pushState("2", null, "/Step1");
  //       const progressValue = 20 * nextStep;
  //       setFormProgress(progressValue);
  //     } else if (nextStep === 3) {
  //       window.history.pushState("3", null, "/Step2");
  //       const progressValue = 20 * nextStep;
  //       setFormProgress(progressValue);
  //     } else if (nextStep === 4) {
  //       window.history.pushState("4", null, "/Step3");
  //       const progressValue = 20 * nextStep;
  //       setFormProgress(progressValue);
  //     } else if (nextStep === 5) {
  //       window.history.pushState("5", null, "/Step4");
  //       const progressValue = 20 * nextStep;
  //       setFormProgress(progressValue);
  //     } else if (nextStep === 6) {
  //       setContext.setSetps(1);
  //       navigate("/");
  //     }
  //   }
  //   if (filter === "saved-report") {
  //     return navigate("/saved-report");
  //   }
  //   if (location.pathname === "/saved-report") {
  //     navigate("/");
  //   }
  //   if (location.pathname === "/report") {
  //     navigate("/");
  //     window.location.reload();
  //   }
  // };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  // const handleBackRairity = () => {
  //   if(from === "diary"){
  //     navigate("/diary-report", {
  //       state: {
  //         Id: pId,
  //         action: "Get",
  //       },
  //     });
  //     return;
  //   }
  //   if (action === "Get" && filter && id && location.pathname === "/report") {
  //     // navigate("/?Step=5", {
  //     //   state: { from: "report", data: filter },
  //     // }
  //     // );
  //     navigate("/");
  //     let backStep = 1;
  //     const stepStr = `?Step=${backStep}`;
  //     console.log("stepStr =>", stepStr, backStep)
  //     window.history.pushState(backStep, null, stepStr);
  //     setSetps(backStep);
  //     // window.history.back();
  //     return;
  //   } else if (
  //     action === "Create" &&
  //     filter &&
  //     location.pathname === "/argyle-report"
  //   ) {
  //     // navigate("/argyle?Step=5", {
  //     //   state: { from: "argyle-report", data: filter },
  //     // });
  //     navigate("/argyle");
  //     let backStep = 1;
  //     const stepStr = `?Step=${backStep}`;
  //     window.history.pushState(backStep, null, stepStr);
  //     setSetps(backStep);
  //     return;
  //   } else if (location.pathname === "/report") {
  //     navigate("/");
  //     window.location.reload();
  //     return;
  //   } else if (location.pathname === "/argyle-report") {
  //     navigate("/argyle");
  //     window.location.reload();
  //     return;
  //   } else if (location.search.includes("Step")) {
  //     let backStep = 1;
  //     const stepStr = `?Step=${backStep}`;
  //     if(stepStr === '?Step=1'){
  //       navigate("/");
  //     }else{
  //       window.history.pushState(backStep, null, stepStr);
  //     }

  //     setSetps(backStep);
  //     console.log("===>", {stepStr,backStep})
  //     return;
  //   }
  //   window.history.back();
  // };



  const backToHome = ()=>{
    window.location.href=`${process.env.REACT_APP_WP_BASE_URL}/rarity-evaluator`;
  }

   const handleBackRairity=()=>{
   console.log(location.pathname)
    if(from === "diary"){
          navigate("/diary-report", {
            state: {
              Id: pId,
              action: "Get",
            },
          });
          return;
      }

      if (location.pathname ===  "/saved-report") {
      
        navigate(-1);
        return;
     }
     if (location.pathname ===  "/rarity-save-history") {
      
      navigate(-1);
      return;
   }
   if (location?.pathname ==="/argyle-save-history"){
    navigate(-1);
    return
   }

   if (location.pathname === "/saved-argyle-report" && location.search ==="?argyle-report" ) {
    console.log("jheduyg>>")
    navigate(-1);
    // navigate('/',{
    //   state :{
    //     tab :'tab2',
    //     showArgyle: showArgyleContent
    //   }
    // })
   }
   
   if (location.pathname === "/saved-argyle-report" ) {
    // navigate(-1);
    navigate('/',{
      state :{
        tab :'tab2',
        showArgyle: showArgyleContent
      }
    })
   
    return;
  }
     if (location.pathname === "/argyle-report" ) {
       navigate("/",{
        state: {
          showArgyle: false,
          tab:'tab2'
        },
       });
       window.location.reload();
       return;
     }
     if (location.pathname === "/report" && steps > 1) {
      navigate("/",{
        state:{
          tab:'tab1',
          showGia:false
        }
      });
       window.location.reload();
       return;
     }
     if (location.pathname === "/report" ) {
      navigate(-1);
      
      return;
    }

     if (location.pathname === "/report") {
      navigate("/",{
        state:{
          tab:'tab1',
          showGia:false
  
        }
      });
      window.location.reload();
      return;
    }


     if (steps > 1 ) {
      const nextStep = steps - 1;
      setContext.setSetps(nextStep);
      if (nextStep === 1) {
       navigate(location.path);
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
        } else if (nextStep === 2) {
        window.history.pushState("2", null, "?Step=1");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 3) {
        window.history.pushState("3", null, "?Step=2");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 4) {
        window.history.pushState("4", null, "?Step=3");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 5) {
        window.history.pushState("5", null, "?Step=4");
        const progressValue = 20 * nextStep;
        setFormProgress(progressValue);
      } else if (nextStep === 6) {
        setContext.setSetps(1);
        navigate(location.path);
      }
    }

        
}

  return (
    <>
      <div className={` rarity-header ${header} `}>
        <div className="container">
          <div
            className={`rarity-top-header 
            ${
              steps === 1 &&
              (location.pathname === "/" &&
              // location.pathname === "/argyle" || 
              activeTab ==="tab2")
                ? "justify-content-end1"
                : " "
            }
            `
          }
          >

            {steps === 1 &&
            (
              location.pathname === "/" || 
            location.pathname === "/argyle") ? (
            location.pathname === "/argyle" && <div className="back-btn" onClick={() => backToHome()} role="presentation">  <img src={leftArrow} alt="" />BACK</div>
            ) : (
              <div className="back-btn" onClick={() => handleBackRairity()} role="presentation">
             <img src={leftArrow} alt="" />
               {location.pathname === '/' ||
                location.pathname === '/report' ||
                location.pathname === '/saved-report' ? (
        backPage === '?report' ? (
          'Report'
        ) : (
          'Rarity'
        )
      ) : location.pathname === '/rarity-save-history' ? (
        'Back'
      ) : backPage === '?argyle-report' ? (
        'Argyle Report'
      ) : (
        'Argyle'
      )}
    </div>
            )}
            <div
             className={`saved-report ${className } ${
              location.pathname === '/saved-report' ||
              location.pathname === '/saved-argyle-report' ||
              location.pathname === '/rarity-save-history'
                ? 'active'
                : ''
            }`}
              onClick={() => handleOpen()}
            >
              
              {
              location.pathname === "/argyle-report" ||
              location?.pathname === "/argyle" ||            
              location.pathname === "/saved-argyle-report" 
                ? "" 
                : location.pathname === "/rarity-save-history"?"Rarity Saved History":"Rarity Search History "}
              {location.pathname === "/rarity-save-history"||location.pathname === "/saved-report" ? (
                <SavedIcon />
              ) : location.pathname === "/saved-argyle-report" ? (
                <SavedIcon />
              ) : (
                // <SaveIcon />
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        {children}
        </div>
      <Footer />
    </>
  );
};

export default Layout;

// const SaveIcon = () => {
//   return (
//     <svg
//       width="24"
//       height="24"
//       viewBox="0 0 24 24"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <path
//         d="M6 3V19.38L10.5945 15.7035C10.9934 15.3844 11.4891 15.2105 12 15.2105C12.5109 15.2105 13.0066 15.3844 13.4055 15.7035L18 19.3785V3H6ZM5.25 1.5H18.75C18.9489 1.5 19.1397 1.57902 19.2803 1.71967C19.421 1.86032 19.5 2.05109 19.5 2.25V20.94C19.4998 21.0811 19.4598 21.2193 19.3846 21.3387C19.3093 21.4581 19.2019 21.5539 19.0747 21.615C18.9475 21.6761 18.8056 21.7001 18.6654 21.6841C18.5252 21.6682 18.3923 21.6131 18.282 21.525L12.468 16.875C12.3351 16.7689 12.1701 16.7111 12 16.7111C11.8299 16.7111 11.6649 16.7689 11.532 16.875L5.718 21.525C5.60772 21.6131 5.47483 21.6682 5.3346 21.6841C5.19438 21.7001 5.0525 21.6761 4.92529 21.615C4.79807 21.5539 4.69068 21.4581 4.61545 21.3387C4.54022 21.2193 4.5002 21.0811 4.5 20.94V2.25C4.5 2.05109 4.57902 1.86032 4.71967 1.71967C4.86032 1.57902 5.05109 1.5 5.25 1.5Z"
//         fill="#6a77a2"
//       />
//     </svg>
//   );
// };

const SavedIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 3.89281V21.2318C4 21.7602 4.42983 22.19 4.95824 22.19C5.1554 22.19 5.34863 22.1309 5.51031 22.0165L11.5713 17.7734L17.6322 22.0165C17.7939 22.1309 17.9871 22.19 18.1843 22.19C18.7127 22.19 19.1425 21.7602 19.1425 21.2318V3.89281C19.1425 2.84782 18.2947 2 17.2497 2H5.89281C4.84782 2 4 2.84782 4 3.89281Z"
        fill="#ffffff"
      />
    </svg>
  );
};
