import React, {useState,useEffect,useRef } from "react";
import Reel from "react-reel";
import { useInView } from "react-intersection-observer";
let counts = 1;
const RarityHighlightedBox = ({ range_slider, cardDesc,animationText,loading }) => {
  const gemologicalRef = useRef();
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  });
  
  let Text = cardDesc?.split("#") || [];
  
  const [showData, setShowData] = useState(false);
  const [animateText, setAnimateText] = useState(animationText-5);
  // const [viewOnce, setViewOnce] = useState(false);
 
  const [hide, setHide] = useState(true);
  const showBoxData = () => {
    setShowData(true);
    setHide(false);
  };
  const stringData = (inputString) => {
    const parts = inputString?.split("~");

    if (parts?.length === 2) {
      const firstString = parts[0].trim();
      const secondString = parts[1].trim().replace(",","")
      return {
        firstString,
        secondString:Number(secondString),
      };
    } else {

      return null;
    }
  };



//   function transformSentence(originalSentence) {
//     var match = originalSentence[1]?.match(/\b(\d+)\s*to\s*(\d+)\b/);

//     if (match) {
       
//         // var endValue = parseInt(match[1]);
//         // var frequency = endValue;
//         var transformedSentence = "a similar diamond enters the market once every " + originalSentence[1] + " years or more";

//         return transformedSentence;
//     } else {
//         return originalSentence;
//     }
// }

const theme = {
  reel: {
    height: "1em",
    display: "flex",
    alignItems: "flex-end",
    overflowY: "hidden",

    /* CUSTOMIZE BELOW */
    fontSize: "32px",
    fontWeight: "600",
    color: "#fff",
    lineHeight: "0.75em", // adjusted for Lato font
   
   
  },
  group: {
    transitionDelay: "0ms",
    transitionTimingFunction: "ease-in-out",
    transform: "translate(0, 0)",
   
    
  },
  number: {
    height: "1em",
    
  },
};


const getReelTheme = () => ({
  ...theme,
 
  reel: {
    ...theme.reel,
    // Add any additional specific properties for the 'reel' object if needed
  },
});



function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}







useEffect(() => {
  setAnimateText(animationText-5)
  if (inView && animationText && !loading) {
   const interval = setInterval(() => {
   
      if (counts <= 5 && animateText<=animationText && !loading ) {
         setAnimateText((old) => old + 1);
         
        counts++;
        
       } else if (counts === 6) {
       
        let elements = document.querySelectorAll("#myDIV");
         elements.forEach(function(element) {
         element.classList.add("animate");
       });
        clearInterval(interval);
        }
 }, 300);
 return () => {
      clearInterval(interval);
      counts=1
    };
   
  }
  // eslint-disable-next-line
}, [inView,animationText,loading]);


const textChecker = (text) => {
  if (text?.includes("<br>")) {
    let newText = text?.split("<br>");
    return {newText};
  } else {
    return null;
  }
};


 return (


    <div id="rarityBox2" className="rarity-highlighted-box">
      {range_slider ? (
        <>
        <div className="rarity-grade-heading-wrapper">
          <span className="grade-transform">Rarity grade</span>{" "}
          <h2 id="myDIV" className="rarity-underline1 grade-info-text grade-transform">
            {range_slider?.rarity_degree}
          </h2>

        </div>
        <p className="mob-pt desk_view">
        {
      textChecker(Text[0]) ? (
   <>
      {textChecker(Text[0])?.newText[0]}
      {textChecker(Text[0])?.newText[1] && <br />}
      {textChecker(Text[0])?.newText[1]}
    </>
     ) : (
    Text[0]
    )
   }
        <strong>{Text[1]}</strong>
        {Text[2]}
      </p>


      <p className="mob-pt phone_view">
        {Text[0]?.replace("<br>"," ")}
        <strong>{Text[1]}</strong>
        {Text[2]}
      </p>
       </>
      ) : (
        <>
        <div ref={(node) => {
        gemologicalRef.current = node;
        inViewRef(node);
      }} className="rarity-grade-heading-wrapper">
          <span className="grade-transform">prevalence </span>{" "}

          <div  className="d-flex align-items-center gap-2">

          <p className={`rarity-text-lowercase rarity-text-size`}>{(stringData(Text[1])?.firstString)} </p>
{
  animationText &&<Reel
  theme={getReelTheme()}
  text={`${numberWithCommas(animateText)}`}
  duration={300}
/>
}
          


          </div>

        </div>
         <p className="mob-pt">
         {/* DIAMONDS WILL HAVE SIMILAR CHARACTERISTICS */}

         {Text[2]}
       </p>
       </>
      )}
      {/* <p className="mob-pt">
        
        {Text}
      </p> */}
      {range_slider ? (
        <>
          <div className="grade-mob-view pt-3">
            {hide && (
              <p className="text-decoration-underline" onClick={showBoxData}>
                Learn about the Rarity levels
              </p>
            )}
          </div>
          {showData && (
            <div className="rarity-card-content rarity-level grade-mob-view mb-3">
              <h6 className="text-light">Rarity</h6>
              <p className="rar-customized-para ">Denomination brakets</p>
              
                   <ul className="m-0 rarity-level-left-padding mob-rarity-level" >
                <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Notable" ? "remarkable-bold" :""} rarity-level-first-para`} >Notable</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">1300-10,000 yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Outstanding" ? "remarkable-bold" :""} rarity-level-first-para`}>Outstanding</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">400-1300<span className="dotColor">.......</span>yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Special" ? "remarkable-bold" :""} rarity-level-first-para`}>Special</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">180-400<span className="dotColor">..........</span>yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Very Special" ? "remarkable-bold" :""} rarity-level-first-para`}>Very Special</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">40-180<span className="dotColor">.............</span>yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Extraordinary" ? "remarkable-bold" :""} rarity-level-first-para`}>Extraordinary</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">10-40<span className="dotColor">................</span>yearly</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Rare" ? "remarkable-bold" :""} rarity-level-first-para`}>Rare</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">3-10<span className="dotColor">.........</span>every year</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Very Rare" ? "remarkable-bold" :""} rarity-level-first-para`}>Very Rare</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">1-3<span className="dotColor">............</span>every year</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim()=== "Extremely Rare" ? "remarkable-bold" :""} rarity-level-first-para`}>Extremely Rare</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">1x<span className="dotColor">............</span>every 1 - 12y</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between">
            <p className={`${range_slider?.rarity_degree?.trim() === "Exceptional" ? "remarkable-bold" :""} rarity-level-first-para`}>Exceptional</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">1x<span className="dotColor">.........</span>every 13 - 24y</p>
          </div>
        </li>
        <li>
          <div className="d-flex justify-content-between ">
            <p className={`${range_slider?.rarity_degree?.trim() === "Remarkable" ? "remarkable-bold" :""} rarity-level-first-para`}>Remarkable</p>
            <p className="rarity-level-scnd-para" />
            <p className="rarity-level-trd text-end">1x<span className="dotColor">.................</span>every 25y</p>
          </div>
        </li>
      </ul>
            </div>
          )}
        </>
      ) : (
        ""
      )}
    </div>

  );
};

export default RarityHighlightedBox;
