/* eslint-disable eqeqeq */
import { useContext, useEffect, useState } from "react";
import { rarityInstance } from "../../config/axios";
import { DiamondColors, DiamondShapes } from "../../utils/diamondsDetail";
import { useNavigate } from "react-router-dom";

import Layout from "../layout/layout";
import Loader from "../loader/loader";
import Spinner from "react-bootstrap/Spinner";
import "./savedReport.css";
import { getlocalStorage } from "../../utils/getlocalStorage";
import { DiaryDiamondShapes } from "../../utils/dairyDiamondsDetail";
import VerticalDropdown from "../verticalDropdown/verticalDropdown";
import { SettingsIcons } from "../verticalDropdown/svgData";
import DiaryRenameDelete from "../Models/diaryRenameDelete";
import FcrfContext from "../../Context";
import SaveNotificationModal from "../Models/saveNotificationModal";
import InfiniteScroll from "react-infinite-scroll-component";
const SavedReport = () => {
  const navigate = useNavigate();
  const getContext = useContext(FcrfContext);
  const { savedNotification, } = getContext;
  const [loading, setLoading] = useState(true);
  const [selectItem, setSelectItem] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [selectedPostId, setSelectedPostId] = useState(null);
  const [show, setShow] = useState(false);
  const initialState = { reportId: "", type: "", recordName: "" };
  const [popupData, setpopupData] = useState(initialState);
  const [dataMsg, setDataMsg] = useState("");
  const[totalReportCount,setTotalReportCount]=useState(0)
  const[hasMore,sethasMore]=useState(true)
  const[page,setPage]=useState(1)

 const limit =20
 const getReport = async (page,limit) => {
  try {
    const response = await rarityInstance().get("report/getreport", {params:{
      page: page,
      limit: limit
    }});
    const { status, getReport } = response?.data;
    if (status === 200) {
      const {data,totalResult } = getReport || {}
      setTotalReportCount(totalResult)
      setReportsData([...reportsData,...data]);
      setLoading(false);
    }
  } catch (error) {
    console.log("error", error);
  }
};
  useEffect(() => {
   let  effectvar=true
    if(effectvar){
      getReport(page,limit);
    }
    return ()=>effectvar=false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  

  const getTime = (time) => {
    let d = new Date(time);
    let pm = d.getHours() >= 12;
    let hour12 = d.getHours() % 12;
    if (!hour12) hour12 += 12;
    let minute = d.getMinutes();
    let date = d.getDate();
    let month = d.toLocaleString("en-US", { month: "short" });
    let year = d.getFullYear();
    return `${month}' ${dateSuffix(date)} ${year} ${hour12}:${
      minute < 10 ? "0" + minute : minute
    } ${pm ? "PM" : "AM"}`;
  };

  function dateSuffix(i) {
    var j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  const handleSelect = (id) => {
    setSelectItem(true);
    setSelectedPostId(id);
  };

  const getCalculation = async (id) => {
    const getReportDataById = reportsData.find(
      (el) => el?.report?.postId === id
    );
    const { report } = getReportDataById || {};
    let diamondColor = DiamondColors.filter(
      (el) => el.value === report.data.color
    );

    let diamondShape = DiamondShapes.filter(
      (el) => el.value === report.data.shape
    );
    const copyfilterdata = { ...report };
    const { is_free, is_subscription, is_role, service } = getlocalStorage();
    copyfilterdata.free = is_free;
    copyfilterdata.subscription = is_subscription || "not active";
    copyfilterdata.role = is_role;
    copyfilterdata.type = "Rarity Search History";
    copyfilterdata.service = service;
    await rarityInstance().post("report/reportwebhook", copyfilterdata);

    navigate("/report", {
      state: {
        id: id,
        action: "Get",
        rarityColor: { color: diamondColor[0].img, shape: diamondShape[0].img },
      },
    });
  };

  // Scroll to View
  // useEffect(() => {
  //   if (selectItem) {
  //     const scrollView = document.getElementById("savedBtn");
  //     scrollView.scrollIntoView({ behavior: "smooth", block: "end" });
  //   } else {
  //     return;
  //   }
  // }, [selectItem]);

  // console.log("reportsData>>>>", reportsData );
  const dropdownItems = [
    
    { icon: SettingsIcons, route: `/settings`, label: "Report Settings" },
  ];

  const handleClosePopup = () => {
    setpopupData(initialState);
    setShow(!show);
  };

  const handleClosePopupAfterSuccess = (reportId,msg) => {
    setShow(false);
    setpopupData(initialState);
    
    if(msg==="Record Deleted successfully!"){
      const updatedReportsData= reportsData.filter(item => 
        item._id !==reportId
        
        );

      setReportsData(updatedReportsData);
    }
    // getReport(page,limit);
  };
  const handleOpenPopup = (data) => {
    setDataMsg(data.type);
    setpopupData(data);
    setShow(!show);
  };

  const fetchData=()=>{
    console.log("hello");
    console.log("totalReportCount",totalReportCount);
    console.log("reportsData?.length",reportsData?.length);
    if(reportsData?.length>=totalReportCount){
      sethasMore(false)
    }
    else{
      setTimeout(() => {
        setPage(page+1)
      }, 1000);
      
    }
    
  }
  

  return (
    
    <div className="saved-report-page" >
      {loading && <Loader type="progress" />}
      
      <Layout >
        <div className="container" >
          <div className="saved-report-wrapper " >
            <div className="saved-report-title">
              <div className="d-flex justify-content-between align-items-center">
              <h1>Rarity Search History</h1>
              <VerticalDropdown dropdownItems={dropdownItems}/>
              </div>
            </div>

           

            <InfiniteScroll
                          dataLength={reportsData?.length}
                          next={fetchData}
                          hasMore={hasMore}
                          loader={<div className="mt-5"><Spinner animation="border" variant="warning" /></div>}
                          style={{ overflow:"hidden",textAlign: 'center',display:"flex",flexDirection:"column",gap:'32px' }}
                          
                        >
            
            {Array.isArray(reportsData) && reportsData?.length > 0
              ? reportsData
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  ?.map((el) => {
                    const { report } = el || {};
                    return (
                      <>
                        {report?.isShow !== false && (
                         
                          <SavedCard
                            weight={report?.data?.weight}
                            color={report?.data?.color}
                            colorName={report?.data?.colorName}
                            shape={report?.data?.shape}
                            saturation={report?.data?.saturation}
                            postId={report?.postId}
                            selectedPostId={selectedPostId}
                            time={getTime(new Date(el?.timestamp).toString())}
                            selectItem={selectItem}
                            handleSelect={handleSelect}
                            getCalculation={getCalculation}
                            report={report}
                            handleOpenPopup={handleOpenPopup}
                            reportId={el?._id}
                          />
                         
                        )}
                        
                      </>
                    );
                  })
                 
                  : "No data found"}
                     <div className="divider"></div>
                  </InfiniteScroll>
                 
         
          </div>
        </div>
        <DiaryRenameDelete
        show={show}
        popupData={popupData}
        handleClosePopup={handleClosePopup}
        handleClosePopupAfterSuccess={handleClosePopupAfterSuccess}
        from="search"
        reportType="report"
      />

{savedNotification && (
        <SaveNotificationModal
          message={
            dataMsg === "delete"
              ? "Report deleted successfully"
              : "Report name has been updated"
          }
          undoBtn={false}
        />
      )}
      </Layout>
      
    </div>
    
  );
};


export default SavedReport;

const SavedCard = ({
  weight,
  color,
  colorName,
  shape,
  saturation,
  postId,
  selectedPostId,
  time,
  handleSelect,
  getCalculation,
  report,
  handleOpenPopup,
  reportId
}) => {
  const [imgScaleVal, setImgScaleVal] = useState(1);
  let rarityColor = DiamondColors.filter((el) => el.value === color);
  let rarityShape;

  if (report?.data?.report_number === "" || !report?.data?.report_number) {
    rarityShape = DiamondShapes.filter((el) => el.value === shape);
  } else {
    rarityShape = DiaryDiamondShapes.filter(
      (elem) => report?.range_slider?.shapeName === elem.value
    );
  }

  const sizeValueInit =
    report?.gia_report?.results?.data?.measurements?.length /
      report?.gia_report?.results?.data?.measurements?.width || 0;

  const sizeValue =
    Number(sizeValueInit.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0]) || 0;


  useEffect(() => {
    if (
      report?.data?.shapeCategory === "Cushion" ||
      report?.data?.shapeCategory === "Radiant"
    ) {
      if (sizeValue <= 1.05) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.06 && sizeValue <= 1.14) {
        setImgScaleVal(1.075);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(1.15);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(1.225);
      } else if (sizeValue >= 1.35) {
        setImgScaleVal(1.3);
      }
    } else if (report?.data?.shapeCategory === "Emerald") {
      if (sizeValue <= 1.14) {
        setImgScaleVal(0.775);
      } else if (sizeValue >= 1.15 && sizeValue <= 1.24) {
        setImgScaleVal(0.85);
      } else if (sizeValue >= 1.25 && sizeValue <= 1.34) {
        setImgScaleVal(0.925);
      } else if (sizeValue >= 1.35 && sizeValue <= 1.44) {
        setImgScaleVal(1);
      } else if (sizeValue >= 1.45) {
        setImgScaleVal(1.075);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sizeValue]);

 
  const  {
    report_number
  } = report?.data || {}

  return (
    <div
      id={`${postId}`}
      className={`saved-card-wrapper flex-nowrap ${
        postId === selectedPostId ? "active" : ""
      } `}
     
      role="presentation"
      key={postId}
    >
      <div className="d-flex w-100 align-items-center gap-3">
      <div
              className="diary-delete-icon"
              onClick={() =>
                handleOpenPopup({ reportId, type: "delete", recordName: "" })
              }
              role="presentation"
            >
              <DeleteIcon />
            </div>
      
      <div className="saved-card-content w-100 text-start"  onClick={() => getCalculation(postId)}>
      
        <span>
          {saturation}
          {"  "}
          {report_number && report_number !=='' && report_number !==null && report_number !== "undefined" ? colorName : color }
          {"  "}
          {`${weight}ct`}
          {"  "}
          {report_number && report_number !=='' && report_number !==null && report_number !== "undefined" ? `(${report_number})` : "" }
        </span>
        <div className="saved-card-date">
          Results reflect the data as of{" "}
          <span className="diamond-date-td">{time}</span>
        </div>
      </div>
      </div>
      <div className="saved-card-img"  onClick={() => getCalculation(postId)}>
        <img
          className={`diamond-saturation ${saturation}`}
          src={`images/diamond-${rarityColor[0]?.img}-${rarityShape[0]?.img}.svg`}
          alt={rarityColor[0]?.img}
          style={{ transform: `scaleY(${imgScaleVal})` }}
        />
      </div>
    </div>
  );
};
const DeleteIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.66666 5.33334L4.34165 18.8331C4.43035 20.6072 5.89457 22 7.67082 22H16.3292C18.1054 22 19.5696 20.6072 19.6583 18.8331L20.3333 5.33334H3.66666ZM5.41874 7.00001L6.00623 18.7499C6.05058 19.6369 6.7827 20.3333 7.67082 20.3333H16.3292C17.2173 20.3333 17.9494 19.6369 17.9937 18.7499L18.5812 7.00001H5.41874Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 5.33333H9.50001V4.5C9.50001 4.03976 9.87311 3.66667 10.3333 3.66667H13.6667C14.1269 3.66667 14.5 4.03976 14.5 4.5V5.33333ZM7.83334 7V4.5C7.83334 3.11929 8.95263 2 10.3333 2H13.6667C15.0474 2 16.1667 3.11929 16.1667 4.5V7H7.83334Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6.16668C2 5.70644 2.3731 5.33334 2.83333 5.33334H21.1667C21.6269 5.33334 22 5.70644 22 6.16668C22 6.62691 21.6269 7.00001 21.1667 7.00001H2.83333C2.3731 7.00001 2 6.62691 2 6.16668Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5 10.3333C14.9602 10.3333 15.3333 10.7064 15.3333 11.1667V15.3333C15.3333 15.7936 14.9602 16.1667 14.5 16.1667C14.0398 16.1667 13.6667 15.7936 13.6667 15.3333V11.1667C13.6667 10.7064 14.0398 10.3333 14.5 10.3333Z"
        fill="#E5E7EF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49999 10.3333C9.96023 10.3333 10.3333 10.7064 10.3333 11.1667V15.3333C10.3333 15.7936 9.96023 16.1667 9.49999 16.1667C9.03975 16.1667 8.66666 15.7936 8.66666 15.3333V11.1667C8.66666 10.7064 9.03975 10.3333 9.49999 10.3333Z"
        fill="#E5E7EF"
      />
    </svg>
  );
};