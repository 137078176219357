import { useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";

const DiamondWeight = ({
  payload,
  handleOnchange,
  inputError,
  setStepScroll,
}) => {
  const inputRef = useRef(null);

  // const screenWidth = window.innerWidth;

  useEffect(() => {
    if (document.readyState === "complete") {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className="diamond-weight-wrapper" id="diamondWeight">
      <h5>Type in the Weight</h5>
      <Form.Group
        className={`diamond-weight ${inputError ? "error" : ""}`}
        controlId="formBasicEmail"
      >
        <Form.Label>Carats</Form.Label>
        <Form.Control
          // type={screenWidth > 991 ? "number" : "text"}
          type="number"
          pattern="[0-9]*/^\d*\.?\d*$/"
          inputMode="decimal"
          placeholder="Enter weight"
          name="weight"
          min="0"
          value={payload?.weight}
          onFocus={() => setStepScroll(false)}
          onChange={(e) => handleOnchange(e.target.value, e.target.name, 1)}
          onBlur={() => setStepScroll(true)}
          onWheel={(e) => e.target.blur()}  
          ref={inputRef}
          autoFocus={true}
        />
        {inputError && (
          <span className="error-text">
            Please enter value between 0.1 to 4
          </span>
        )}
      </Form.Group>
    </div>
  );
};

export default DiamondWeight;
