export const queryString = () => {
  let query = window.location.search.substr(1);
  // console.log("query =>", query)
  let resultval = {};
  query.split('&').forEach((part) => {
    let item = part.split('=');
    resultval[item[0]] = decodeURIComponent(item[1]);
  });
  return resultval;
};
