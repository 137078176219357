import { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../footer/footer";
import Sidebar from "./sidebar";
import leftArrow from "../../assets/images/rarity/icon-arrow-left.svg";
import "./layout.css";
import FcrfContext from "../../Context";

const DiaryLayout = ({
  children,
  sideBar,
  header,
  handleSideBarCard,
  sidebarCard,
  from,
  handleCheckLogin,
  className,
}) => {
  const { setPrevLocation, navigateFromPage, } =
    useContext(FcrfContext);
  const navigate = useNavigate();
  const location = useLocation();
  const screenWidth = window?.innerWidth;
  const [openSidebar, setOpenSidebar] = useState(
    screenWidth < 1024 ? false : true
  );

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  // const handleOpenSearchHistory = () => {
  //   if (
  //     localStorage.getItem("rarity#@user") === "undefined" ||
  //     !localStorage.getItem("rarity#@user")
  //   ) {
  //     return handleCheckLogin();
  //   }

  //   if (
  //     location.pathname === "/diary" ||
  //     location.pathname === "/diary-report"
  //   ) {
  //     setNavigateFromPage(location.pathname);
  //   }

  //   let options = undefined;
  //   if (
  //     navigateFromPage === "/diary-report" ||
  //     location.pathname === "/diary-report"
  //   ) {
  //     options = {
  //       state: {
  //         Id: location.state.Id,
  //       },
  //     };
  //   }
  //   return navigate("/diary-history-report", options);
  // };

  // const handleOpenSavedHistory = () => {
  //   if (
  //     localStorage.getItem("rarity#@user") === "undefined" ||
  //     !localStorage.getItem("rarity#@user")
  //   ) {
  //     return handleCheckLogin();
  //   }

  //   if (
  //     location.pathname === "/diary" ||
  //     location.pathname === "/diary-report"
  //   ) {
  //     setNavigateFromPage(location.pathname);
  //   }
  //   let options = undefined;
  //   if (
  //     location.pathname === "/diary-report" ||
  //     navigateFromPage === "/diary-report"
  //   ) {
  //     options = {
  //       state: {
  //         Id: location?.state?.Id,
  //       },
  //     };
  //   }
  //   return navigate("/diary-save-history", options);
  // };

  const handleBack = () => {
    if (location.pathname === "/diary-report") {
      navigate("/diary", undefined);
      setPrevLocation("");
    } else {
      if (
        location.pathname === "/diary-history-report" ||
        location.pathname === "/diary-save-history"
      ) {
        setPrevLocation("/diary-history-report");
       
        if (navigateFromPage === "/diary") {
          setPrevLocation("");
        }
      
          navigate(-1);
  
      }
      if (navigateFromPage === "/diary-report") {
        navigate(navigateFromPage, {
          state: {
            Id: location?.state?.Id,
          },
        });
      } else {
        navigate(navigateFromPage);
      }
    }
  };

  return (
    <div
      className={`diary-main-page ${
        location.pathname === "/diary"
          ? "diary-home-page"
          : location.pathname === "/diary-report"
          ? "diary-report-page"
          : ""
      }`}
    >
      {header ? (
        <div className={`${className?className:""} rarity-header diary-header`}>
          <div className={`container ${openSidebar ? "open" : ""}`}>
            <div
              className={`diary-top-header ${
                location.pathname === "/diary" ? "dairy-input-page" : ""
              }`}
            >
              {location.pathname !== "/diary" ? (
                <div
                  className='back-btn'
                  onClick={() => handleBack()}
                  role='presentation'
                >
                  <img src={leftArrow} alt='' /> Back
                </div>
              ) : (
                ""
              )}
              {/* <div className='saved-btn-rapper'>
                {location.pathname !== "/diary" && location.pathname !== "/diary-report" &&<div
                  className={`back-btn search-history-btn ${
                    location.pathname === "/diary-save-history" ? "active" : ""
                  }`}
                  onClick={() => handleOpenSavedHistory()}
                  role='presentation'
                >
                  <span>Saved Report</span> <SaveIcon />
                </div>}
                {location.pathname !== "/diary" && location.pathname !== "/diary-report" &&<div
                  className={`back-btn search-history-btn ${
                    location.pathname === "/diary-history-report"
                      ? "active"
                      : ""
                  }`}
                  onClick={() => handleOpenSearchHistory()}
                  role='presentation'
                >
                  <span>Search History</span> <HistoryIcon />
                </div>}
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className='diary-content'>
        {sideBar ? (
          <Sidebar
            handleSideBarCard={handleSideBarCard}
            openSidebar={openSidebar}
            setOpenSidebar={setOpenSidebar}
          />
        ) : (
          ""
        )}
        <div className='content'>{children}</div>
      </div>
      <Footer />
    </div>
  );
};

export default DiaryLayout;

// const SaveIcon = () => {
//   return (
//     <svg
//       width='24'
//       height='24'
//       viewBox='0 0 24 24'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//     >
//       <path
//         d='M6 3V19.38L10.5945 15.7035C10.9934 15.3844 11.4891 15.2105 12 15.2105C12.5109 15.2105 13.0066 15.3844 13.4055 15.7035L18 19.3785V3H6ZM5.25 1.5H18.75C18.9489 1.5 19.1397 1.57902 19.2803 1.71967C19.421 1.86032 19.5 2.05109 19.5 2.25V20.94C19.4998 21.0811 19.4598 21.2193 19.3846 21.3387C19.3093 21.4581 19.2019 21.5539 19.0747 21.615C18.9475 21.6761 18.8056 21.7001 18.6654 21.6841C18.5252 21.6682 18.3923 21.6131 18.282 21.525L12.468 16.875C12.3351 16.7689 12.1701 16.7111 12 16.7111C11.8299 16.7111 11.6649 16.7689 11.532 16.875L5.718 21.525C5.60772 21.6131 5.47483 21.6682 5.3346 21.6841C5.19438 21.7001 5.0525 21.6761 4.92529 21.615C4.79807 21.5539 4.69068 21.4581 4.61545 21.3387C4.54022 21.2193 4.5002 21.0811 4.5 20.94V2.25C4.5 2.05109 4.57902 1.86032 4.71967 1.71967C4.86032 1.57902 5.05109 1.5 5.25 1.5Z'
//         fill='#6a77a2'
//       />
//     </svg>
//   );
// };

// const HistoryIcon = () => {
//   return (
//     <svg
//       width='24'
//       height='24'
//       viewBox='0 0 24 24'
//       fill='none'
//       xmlns='http://www.w3.org/2000/svg'
//     >
//       <path
//         d='M3 12C3 13.78 3.52784 15.5201 4.51677 17.0001C5.50571 18.4802 6.91131 19.6337 8.55585 20.3149C10.2004 20.9961 12.01 21.1743 13.7558 20.8271C15.5016 20.4798 17.1053 19.6226 18.364 18.364C19.6226 17.1053 20.4798 15.5016 20.8271 13.7558C21.1743 12.01 20.9961 10.2004 20.3149 8.55585C19.6337 6.91131 18.4802 5.50571 17.0001 4.51677C15.5201 3.52784 13.78 3 12 3C9.48395 3.00947 7.06897 3.99122 5.26 5.74L3 8'
//         stroke='#6A77A2'
//         strokeWidth='2'
//         strokeLinecap='round'
//         strokeLinejoin='round'
//       />
//       <path
//         d='M3 3V8H8M12 7V12L16 14'
//         stroke='#6A77A2'
//         strokeWidth='2'
//         strokeLinecap='round'
//         strokeLinejoin='round'
//       />
//     </svg>
//   );
// };
